/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";

// Bootstrap (4.1.3) components

@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon utilities and components

@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)

@import "custom/vendors";


// React differences
@import "react/react-differences";

body {
	background-color: #f0f0f0c4;
	font-family: Poppins;
}

.bg-cover {
	background-size: cover !important;
	min-height: 100vh;
	max-width: 100vw;
}

.text-escape {
	color: #0b2564
}

.font-weight-max {
	font-weight: 900;
}

// home
.bg-header-home {
	background-color: #3A317D;
	height: 270px;
	// color: #fff;
	border-bottom-right-radius: 47%;
	border-bottom-left-radius: 10%;
}

.menu-home {
	// height: 50px;
	// line-height: 3;
	// border-radius: 5px;
	// background-color: #fff;
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 3;
	border-radius: 50%;
	background-color: #fff;
}

@font-face {
	font-family: 'Helena';
	src: url('../fonts/helena/Helena.otf');
	src: url('../fonts/helena/Helena.ttf') format('truetype');
}

// slider
#ReactBackgroundSlider>figure {
	background-attachment: fixed;
	background-position: top center !important;
	position: fixed !important;
}

// main nav tab
.main-tab-card {
	cursor: pointer;
	height: 60px;
	line-height: 40px;
	font-size: 16px;
	font-weight: 600;
	color: #FFFFFF;
	width: 50%;
	text-align: center;
	padding: 5px;
	margin: 0;
}

.main-tab-card .nav-link.active {
	// border: none;
	//border-bottom: 3px solid #0085e7;
	color: #4e4d4d !important;
	border-radius: 50px;
	transition: opacity 0.75s;
}

.main-tab-card .nav-link:hover {
	border: none;
}

// Effect button
.btn-effect-click:active {
	background-color: #0085E7;
	transform: translateY(2px);
}

//---------------------------
.custom-nav {
	border-radius: 50px;
	background-color: #5b5876;
	border: none;
}

// income tab
.income-tab-card {
	height: 50px;
	line-height: 40px;
	width: calc(100% / 3);
	text-align: center;

	color: #1D2129;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	text-transform: uppercase;
}

.income-tab-card .nav-link.active {
	border: none;
	border-bottom: 3px solid #0085e7;
	color: #333333 !important;
	font-weight: 500;
	color: #0085E7 !important;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	text-transform: uppercase;
}

.income-tab {
	background-color: #fff;
	height: auto;
	border-radius: 12px;
	color: #000;
	border: 1px solid;

	.request-staff {
		border-radius: 5px;
		background: #ECF4FE;
		display: flex;
		padding: 1px 2px;
		justify-content: center;
		align-items: center;
		margin-left: 4px;

		p {
			color: #0085E7;
			font-family: Poppins;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin: 0;
		}
	}
}

.income-tab .income-total {
	color: #1D2129;
	text-align: center;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 137.5%;

	/* 24.75px */
	span {
		color: #686868;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 137.5%;
		/* 22px */
	}
}

.income-tab .income-info {
	text-align: center;
	padding: 16px 0;
	color: #1D2129;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 137.5%;
	/* 19.25px */
}

.income-tip-supply-fee-total {
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 24px;
	align-self: stretch;
	border-radius: 5px;
	background: #EEF4FF;
	margin: 0 16px;
	margin-top: 8px;
}

.income-tip-supply-fee-total .income-tip-supply-item {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: start;
	flex: 1 0 0;
}

.income-tip-supply-fee-total .income-tip-supply-item span {
	color: #232F3E;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 137.5%;

	/* 19.25px */
	&.value {
		font-size: 16px;
		font-weight: 600;
	}
}

.income-history .title {
	color: #686868;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.income-list {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.income-list .income-item {
	border-radius: 5px;
	background: #E5E6EB;
	padding: 8px;
}

.income-list .income-item .info {
	display: flex;
	align-items: center;
	gap: 4px;
}

.income-list .income-item .info .income-id {
	font-size: 16px;
	color: #273D52;
	font-weight: 600;
	border-radius: 5px;
	background: #1D2129;
	display: inline-flex;
	padding: 2px 4px;

	p {
		color: #fff;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
		margin: 0;
	}
}

.income-list .income-item .info .date {
	font-size: 16px;
	font-weight: 600;
	color: #273D52;
	opacity: 0.7;
}

.income-list .income-item .info {
	width: 100%;

	.customer-name {
		flex: 1;
		color: #1D2129;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 137.5%;

		/* 16.5px */
		span {
			color: #1D2129;
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 137.5%;
			/* 16.5px */
		}
	}

	.total-price {
		color: #0C719C;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 137.5%;
		/* 22px */
	}
}

.income-list .income-item .star-ratings {
	line-height: 1px;
}

.income-list .income-item .ticket-rating {
	display: flex;
	align-items: center;
	gap: 8px;

	.star-ratings {
		// margin-bottom: 3px;
		line-height: 1px;
	}

	.create-date {
		flex: 1;
		color: #1D2129;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 1;
		display: flex;
		align-self: center;

		&.tip {
			line-height: 1;
			height: 10px;
			flex: unset;
		}
	}
}

.income-list .income-item .btn-view-detail {
	line-height: 70px;
}

// ticket details
.ticket-detail {
	border-radius: 10px;
	overflow: hidden;
}

.ticket-detail .ticket-info {
	background-color: #e9e9e9;
	padding-right: 16px;
	padding-left: 16px;
	padding: 16px;
}

.ticket-detail .ticket-info .ticket-id {
	flex: 1;

	color: #0085E7;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

}

.ticket-detail .ticket-info .ticket-time {
	color: #686868;
	text-align: right;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.ticket-detail .ticket-info .customer-info {
	display: flex;
	align-items: center;

	.customer-name {
		color: #1D2129;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 137.5%;
		/* 19.25px */
		flex: 1;
	}

	.customer-phone {
		color: #686868;
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 137.5%;
		/* 16.5px */
		display: flex;
		align-items: center;

		.icon-phone {
			display: flex;
		}

		span {
			color: #686868;
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 137.5%;
			/* 16.5px */
		}
	}
}

.ticket-detail .ticket-info .service-title {
	color: #1D2129;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ticket-detail .ticket-invoice {
	background-color: #fff;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.ticket-detail .ticket-invoice .title {
	flex: 1;
	color: #1D2129;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 137.5%;
	/* 19.25px */
}

.ticket-detail .ticket-invoice .money {
	font-size: 18px;
	color: #1D2129;
	text-align: right;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 137.5%;
	/* 19.25px */
}

.ticket-detail .ticket-invoice .total {
	color: #1D2129;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 137.5%;
	/* 24.75px */
}

.ticket-detail .ticket-invoice .supply-row {

	.money,
	.title {
		color: #0085E7;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 137.5%;
		/* 19.25px */
	}
}


//ticket-customer
//customer-info
.ticket-customer .customer-info {
	background: url('../img/ticket/bg.png') no-repeat;
	background-position: center;
	background-size: cover;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}

.ticket-customer .customer-info .info {
	color: #fff;
	flex: 1;
}

.ticket-customer .customer-info .info .name {
	font-size: 19px;
	font-weight: 600;
}

.ticket-customer .customer-info .info .type {
	font-size: 14px;
	margin-bottom: 13px;
}

//ticket-customer
//customer-feedback
.ticket-customer .customer-feedback {
	background-color: #fff;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	color: #273D52;
	opacity: 0.9;

	.blank-reviews {
		color: #686868;
		text-align: left;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 137.5%;
		/* 19.25px */
		margin: 0;
	}
}

.ticket-customer .feedback-border {
	// border: 1px solid #E3E3E8;
	// border-top: none;
}

.ticket-customer .customer-feedback {
	padding-bottom: 16px;

	.feedback-text {
		color: #686868;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 137.5%;
		/* 19.25px */
	}

	.feedback-comment {
		p {
			margin: 0;
			padding: 0;
		}

		.title {
			color: #686868;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;
			/* 19.25px */
		}

		.comment {
			color: #1D2129;
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 137.5%;
			/* 16.5px */
		}
	}
}

.ticket-customer .customer-feedback .smile .item-smile {
	flex: 1;
}

.ticket-customer .customer-feedback .smile .item-smile img {
	// background-color: #cecece;
	// border-radius: 50%;
}

.ticket-customer .customer-feedback .smile .item-smile .status {
	margin-top: 3px;
	color: #686868;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 137.5%;
	/* 16.5px */
}

// ----------Appointment
.btn-assign-to {
	background-color: rgba(39, 61, 82, 0.04);
	box-sizing: border-box;
	color: #273D52;
	font-size: 14px;
	font-weight: 600;
	border: 1px solid #273D52;
}

//------------------------Login
.bg-login {
	background: url('../img/background/login.png') no-repeat;
	background-attachment: fixed;
	background-position: top center !important;
	background-attachment: fixed;
	background-position: top center !important;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0;
	z-index: -1000;
	margin: 0;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
}

#title-login {
	text-shadow: 2px 2px 4px #bfbfbf;
	position: relative;
	text-align: center;
	top: 160px;
}

// Assign appointment
.label-assign-appoint {
	color: "#686868";
	font-size: '15px';
	font-weight: '500';
}

// animation duration
.tab-pane.active,
#form-phone-number,
#form-verify,
#form-salon,
.income-tab,
.income-history .income-item {
	animation-name: fadeIn;
	animation-duration: 600ms;
	animation-timing-function: linear;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}


//Reassign Tickets
.reassign-tickets .ticket-item {
	background-color: #fff;
	border-radius: 10px 10px 0 0;
}

.reassign-tickets .ticket-item .ticket-id {
	font-size: 20px;
	font-weight: 600;
	color: #303030;
}

.reassign-tickets .ticket-item .ticket-id span {
	color: #3778EC;
}

.ticket-item .title {
	color: #686868;
	font-size: 17px;
	letter-spacing: 0;
}

.ticket-item .name {
	color: #2C2C2C;
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 0;
}

.ticket-item .content {
	box-sizing: border-box;
	height: auto;
	width: auto;
	border: 1px solid #E3E3E8;
	border-radius: 4px;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
	padding: 5px;
	color: #2C2C2C;
}

.ticket-item .staff-list p {
	color: #686868;
	font-size: 18px;
	line-height: 21px;
}

.ticket-item .staff-list {
	margin: 15px 0 10px;

	.staff-item {
		margin-right: 15px;
		text-align: center;

		&.selected {
			>div {
				border-color: rgb(17, 145, 225);
			}

			>h5 {
				font-weight: bold;
			}
		}

		>div {
			height: 50px;
			width: 50px;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto;
			border: 2px solid transparent;
			@include transition();
		}

		>h5 {
			font-size: 13px;
			margin-top: 5px;
			@include transition();
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

// Overview
.overview .title {
	color: #444444;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0;
}

.overview .title-box {
	height: 44px;
	background-color: #f9f9f9;
	line-height: 45px;
}

#box-month .title {
	color: #18181D;
	font-weight: 600;
}

.overview .statistic-section {
	background-color: #fbfbfb;
	margin-bottom: 0px;
	// border-radius: 5px;
	// border: 1px solid #efefef;
}

.overview .services .progress-bar {
	background-color: #C0CC3D;
}

.type-customer #appointment .progress-bar {
	background-color: #3a66c4;
}

.type-customer #walkIn .progress-bar {
	background-color: #ffbc1f;
}

.type-customer #other .progress-bar {
	background-color: #7ddafa;
}

#gross-sale .title {
	color: #757585;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0.5px;
}

#gross-sale .total {
	font-size: 22px;
	font-weight: 600;
	color: #18181D;
}

#gross-sale .percent span {
	font-size: 14px;
	font-weight: 600;
}

// menu manager
#manager-menu {
	z-index: 9999999999999;
	position: absolute;
	width: 100%;
	height: auto;
	background: #ebebeb;
	left: 0;
	text-align: center;
	// animation-duration: 600ms;
	// animation-timing-function: linear;
}

#manager-menu-img {
	height: 200px;
}

#manager-menu-img #open {
	animation-name: openMenuManager;
	animation-fill-mode: forwards;
}

@keyframes openMenuManager {
	from {
		max-height: 0;
	}

	to {
		max-height: 1000px;
	}
}

#manager-menu-img #close {
	animation-name: closeMenuManager;
	animation-fill-mode: both;
}

@keyframes closeMenuManager {
	from {
		max-height: 1000px;
	}

	to {
		max-height: 0;
	}
}

#manager-menu ul {
	line-height: 30px;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

#manager-menu ul li {
	margin-top: 10px;
	border-bottom: 1px solid #fff;
	-webkit-animation-duration: 1s;
	-webkit-animation-delay: 1s;
	-webkit-animation-iteration-count: 1;
	-moz-animation-duration: 1s;
	-moz-animation-delay: 1s;
	-moz-animation-iteration-count: 1;
	-o-animation-duration: 1s;
	-o-animation-delay: 1s;
	-o-animation-iteration-count: 1;
	animation-duration: 5s;
	animation-delay: 5s;
	animation-iteration-count: 1;
}

#manager-menu ul li a {
	font-size: 18px;
	font-weight: 600;
	color: #444444;
	display: inline-block;
	width: 100%;
}

#manager-menu ul li a.active span {
	border-bottom: 4px solid #0085e7;
}

// Notification have a new review 
#new-review-notify {
	background-color: #FFD881;
	margin-bottom: 10px;
	border-radius: 5px;
	padding: 8px 10px;
	height: 50px;
}

#new-review-notify div {
	background-color: #ffffff;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	text-align: center;
	float: left;
	margin-right: 10px;
}

#new-review-notify div img {
	margin-top: 7px;
}

#new-review-notify span {
	line-height: 35px;
	font-size: 15px;
	color: #18181D;
}

// scroll
.scroll-style {
	max-height: 95%;
}

// ---------------------------------
.overview-custom-nav {
	display: flex;
	background-color: #FFFFFF;
}

.overview-custom-nav .nav-item {
	flex: 1;
	text-align: center;
}

.overview-custom-nav .nav-item>.nav-link {
	height: 50px;
	line-height: 43px;
	// font-size: 15px;
	font-weight: 600;
}

.overview-custom-nav .nav-item>.nav-link.active {
	background-color: #FFFFFF;
	color: #4692F4;
	border: none;
	border-bottom: 4px solid #4692F4;
}

.overview #sales {
	background: #fff;
	height: 100%;
}

.overview .sales-item {
	margin: 10px;
	padding: 10px;
	border-radius: 10px;
	background-color: rgba(70, 146, 244, 0.1);
}

.overview .sales-item h3 {
	font-weight: 400;
}


//---------------------
// Appointments
.appointment-item {
	border-radius: 10px;
	padding: 10px 0;
	font-size: 15px;
	color: #000;
}


.select-staff-item .logo-staff {
	margin: 20px 20px 2px 20px;
	height: 80px;
	width: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px solid #f59e2d;
	overflow: hidden;
}

.time-item {
	background-color: #C4C4C4;
	border-radius: 5px;
	width: 71px;
	height: 30px;
	line-height: 27px;
	display: inline-block;
	text-align: center;
	font-weight: 600
}

.detail-appointment-modal .modal-content .close span {
	color: #ffffff;
	font-size: 3.25rem;
}

.ticket-by-staff {
	border: 1px solid;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
}

.star-ratings {
	display: flex !important;
}

.new-appointment-wrapper {
	background-color: #F3F3F3;
	height: 100vh;
	display: flex;
	flex-direction: column;

	p {
		margin: 0;
	}

	.new_appointment__header {
		background: #fff;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
		display: flex;
		width: 100%;
		padding: 9px 16px 10px 16px;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;

		p.title {
			color: #1D2129;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.new_appointment__container {
		flex: 1;
		position: relative;
		z-index: 1;

		.new_appointment__container_box {
			position: absolute;
			inset: 0;
			padding: 0px 15px 20px 15px;
			overflow-y: auto;
			overflow-x: hidden;

			// Hide scrollbar for Chrome, Safari, and Opera
			&::-webkit-scrollbar {
				display: none;
			}

			// Hide scrollbar for Internet Explorer and Edge
			-ms-overflow-style: none;

			// Hide scrollbar for Firefox
			scrollbar-width: none;

			.container-box {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}

	.new_appointment__title {
		margin-top: 16px;
		margin-bottom: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;

		.step_count {
			color: #686868;
			text-align: center;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.step_label {
			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-transform: uppercase;
		}
	}

	.new_appointment__categories {
		display: flex;
		flex-wrap: nowrap;
		overflow-y: hidden;
		overflow-x: auto;

		// Hide scrollbar for Chrome, Safari, and Opera
		&::-webkit-scrollbar {
			display: none;
		}

		// Hide scrollbar for Internet Explorer and Edge
		-ms-overflow-style: none;

		// Hide scrollbar for Firefox
		scrollbar-width: none;
		margin: 15px 0px;

		.button_category {
			text-align: center;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 18px;
			cursor: pointer;
			border: none;
			outline: none;
			padding: 0;
		}

		.new_appointment__category {
			margin: 0;
			padding: 0;
			display: flex;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 20px;
			background: transparent;
			color: #333;
			font-size: 14px;
			text-transform: uppercase;
		}

		.active {
			background: #0C719C;
			color: white;
			height: 100%;
		}
	}

	.new_appointment__services {
		.button_service {
			text-align: center;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 18px;
			cursor: pointer;
			border: none;
			outline: none;
			padding: 0;
			min-width: 100px;
			position: relative;
		}

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		gap: 10px;

		.new_appointment__service {
			display: flex;
			align-items: center;
			border: 1px solid #ccc;
			border-radius: 10px;
			background-color: #fff;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

			.serviceImage {
				width: 40px;
				height: 40px;
				border-radius: 8px;
				margin: 10px;
				background-color: #979797;
			}

			.flex-column {
				display: flex;
				text-align: left;
				margin-left: -3px;
				flex: 1;
				overflow: hidden;
			}

			.service-row-content {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.serviceTitle {
				color: #1D2129;
				font-size: 14px;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				margin-bottom: 5px;
			}

			.servicePrice {
				font-size: 14px;
				color: #1D2129;
			}

			.serviceDuration {
				font-size: 14px;
				color: #1D2129;
				text-transform: lowercase;
			}
		}
	}

	.new_appointment__staffs {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		; // Adjust size as necessary
		gap: 6px;
		justify-items: center;
		align-items: center;

		.new_appointment__staff {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 85px;
			height: 85px;
			padding: 5px;
			border: none;
			outline: none;
			cursor: pointer;
			background-color: #E5E6EB;
			border-radius: 10px;

			&.active {
				background-color: #ECF4FE;
				border: 1px solid #0085E7;
			}

			.outbox_staffAvatar {
				position: relative;

				.tick {
					position: absolute;
					top: 15px;
					left: 15px;
				}
			}

			img.staffAvatar {
				width: 45px;
				height: 45px;
				object-fit: cover;
				border-radius: 50%;
				margin-bottom: 5px;
				background: #ccc;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				margin-bottom: 5px;
				font-size: 14px;
			}
		}
	}

	.new_appointment__request_staff {
		display: flex;
		margin: 15px 0px;

		p {
			margin-left: 10px;
			font-size: 14px;
			color: #1D2129;
		}
	}

	.new_appointment__time_slots_block {
		.new_appointment__time_slots_label {
			font-size: 14px;
			font-weight: 500;
			color: #1D2129;
			line-height: 21px;
			margin: 15px 0px;
		}

		.new_appointment__time_slots {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 8px;

			.new_appointment__time_slot {
				border-radius: 5px;
				border: 1px solid #C9CDD4;
				background: white;
				padding: 3px;
				font-size: 14px;
				line-height: 21px;

				p {
					color: #232F3E;
				}

				&.active {
					border: 1px solid #0085E7;
					background: #ECF4FE;
				}
			}
		}

	}

	.new_appointment__group {
		.new_appointment__group_button {
			display: flex;

			p {
				margin-left: 4px;
			}
		}

		.group-configure {
			display: flex;
			align-items: center;
			justify-content: space-between;

			button {
				box-shadow: none;
				outline: none;
				border: none;
				background: transparent;
				padding: 0;
			}

			label {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin: 0;
			}

			.group-actions {
				display: flex;
				flex-direction: row;
				gap: 18px;
				align-items: center;

				.count {
					color: #1D2129;
					text-align: center;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					/* 120% */
				}
			}
		}

		.group-number-rows {
			display: flex;
			align-items: center;
			gap: 12px;

			button {
				padding: 0;
				flex: 1;
				box-shadow: none;
				outline: none;
				border: none;
				background: transparent;
			}

			margin-top: 8px;

			.number-box {
				background-color: #E5E6EB;
				display: flex;
				padding: 4px 0;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				width: 100%;

				.text {
					color: #1D2129;
					text-align: right;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;
					/* 19.25px */
				}

				&.active {
					background-color: #86909C;

					.text {
						color: #fff;
					}
				}
			}
		}

	}

	.new_appointment__review_box_rows {
		margin: 10px 0px;
		display: grid;
		grid-template-columns: 5fr 3fr;
		gap: 8px;
	}

	.new_appointment__review_box {
		display: flex;
		flex-direction: column;

		.title {
			font-size: 14px;
		}

		button {
			box-shadow: none;
			outline: none;
			border: none;
			background: transparent;
			padding: 0;
		}

		.new_appointment__review_box_top {
			display: flex;
			align-items: center;
			gap: 8px;

			button {}
		}

		.new_appointment__review_box_content {
			p {
				color: #1D2129;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				/* 171.429% */
			}

			.staff-box {
				display: flex;
				align-items: center;
				gap: 8px;

				.staff-avatar {
					border-radius: 100px;
					width: 32px;
					height: 32px;
					background-color: #979797;
				}

				min-height: 30px;
			}

			.services-box {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				gap: 8px;

				.service {
					padding: 4px 8px;
					background: #FFFFFF;
					border: 1px solid #C9CDD4;
					border-radius: 5px;
					text-align: left;

					.name {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}

					.price {
						color: #1D2129;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}
	}

	.request-staff-box {
		display: flex;
		padding: 1px 2px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 5px;
		background: #F4FCFF;

		p {
			color: #0085E7;
			font-family: Poppins;
			font-size: 10px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.new_appointment__coupon {
		.box-coupon {
			position: relative;
			border: none;
			outline: none;

			.absolute-icon {
				position: absolute;
				top: 30px;
				right: 30px;
			}

			.tick-icon {
				width: 24px;
				height: 24px;

				background-color: #fff;
				border: 3px solid #0085E7;
				border-radius: 2px;
			}

			&.active {
				border: none;

				.tick-icon {
					background-color: #0085E7;
				}
			}

			&.focus {
				border: none;
			}
		}
	}
}

.react-calendar.calendar_kit {
	.react-calendar__navigation {
		button {
			box-shadow: none;
			outline: none;
			border: none;
			background: transparent;

			&.react-calendar__navigation__prev2-button,
			&.react-calendar__navigation__next2-button {
				display: none;
			}

			&.react-calendar__navigation__label {
				pointer-events: none;
			}
		}
	}

	.react-calendar__viewContainer {
		.react-calendar__month-view__weekdays {
			.react-calendar__month-view__weekdays__weekday {
				text-align: center;

				abbr {
					text-decoration: none;
					color: #1D2129;
					text-align: center;
					font-family: Poppins;
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.react-calendar__month-view__days {
			button.react-calendar__tile {

				box-shadow: none;
				outline: none;
				border: none;
				background: transparent;

				text-align: center;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: #1D2129;

				&:disabled,
				&.react-calendar__month-view__days__day--neighboringMonth {
					color: #686868;
					opacity: 0.5;
				}

				&.react-calendar__tile--now {
					color: #0085E7;
				}

				&.react-calendar__tile--active {
					border-radius: 100px;
					background: #0085E7;
					color: #FFF;
				}
			}
		}
	}
}

.coupon_kit {
	.bottom-content-styled {
		.expire_date {
			color: black;
			font-size: 12px;
			font-style: normal;
			line-height: normal;
			font-weight: bold;
			font-weight: 900;
			font-family: 'Roboto', sans-serif;

			b {
				color: black;
				font-size: 14px;
				font-style: normal;
				line-height: normal;
				font-weight: 900;
				font-family: 'Roboto', sans-serif;

			}
		}

		.title {
			color: black;
			font-size: 1.25rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		.list-description {
			flex: 1;
			padding-right: 10px;
		}

		ul {
			margin: 0;
			padding-left: 15px;

			li,
			p {
				color: black;
				font-size: 14px;
				font-style: normal;
				line-height: 15px;
				font-weight: 900;
				font-family: 'Roboto', sans-serif;

				b {
					font-weight: 700;
				}
			}
		}

		.expire-group {
			transform: translateY(-5px);
		}

		.couponCode {
			color: black;
			text-align: center;
			font-size: 12px;
			font-style: normal;
			line-height: normal;
			width: 85px;
			font-weight: 900;
			font-family: 'Roboto', sans-serif;
		}
	}

	.coupon-info-box-styled {
		border-radius: 5px;
		background: var(--fill-fill-5, #E5E6EB);
		max-width: 374px;

		.divider {
			background: #86909C;
			width: 2px;
		}

		.selling-box {
			border-radius: 5px;
			padding: 1rem;
			background: #fff;

			.box {
				border-radius: 5px;
				display: flex;
				background-image: url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'80%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' stroke=\'black\' stroke-width=\'7\' stroke-dasharray=\'7%2c 15\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");

				&.active {
					background-image: url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'80%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' stroke=\'red\' stroke-width=\'7\' stroke-dasharray=\'7%2c 15\' stroke-dashoffset=\'0\' stroke-linecap=\'square\'/%3e%3c/svg%3e");
				}

				.content {
					display: flex;
					padding-left: 12px;

					.currency {
						color: black;
						font-family: Roboto;
						font-size: 35px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}

					.money {
						color: black;
						font-family: Roboto;
						font-size: 100px;
						font-style: normal;
						font-weight: 700;
						line-height: 102px;
					}

					.end {
						display: flex;
						flex-direction: column;

						.end-top {
							display: flex;
							gap: 8px;

							.value {
								color: black;
								font-family: Roboto;
								font-size: 35px;
								font-style: normal;
								font-weight: 400;
							}

							.percent {
								color: black;
								font-family: Roboto;
								font-size: 40px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}

							.label {
								color: black;
								font-family: Roboto;
								font-size: 55px;
								font-style: normal;
								font-weight: 700;
								align-self: center;

								&.absolute {
									line-height: 0px;
									position: absolute;
									top: 24px;
									left: 0;
								}
							}
						}

						.end-bottom {
							color: black;
							font-family: Roboto;
							font-size: 18px;
							font-style: normal;
							font-weight: 700;
							transform: translateY(-7px);
							white-space: nowrap;
						}
					}

					&.size2 {
						padding-left: 0;

						.end-bottom {
							font-size: 16px;
						}
					}

					&.percent {
						padding-left: 0;
						justify-content: center;
					}
				}
			}
		}
	}

	.coupon-container-wrap-styled {
		padding: 1rem;

		&.isLarge {
			padding-top: 0rem;
		}
	}

	.coupon-ui-styled {
		.row {
			display: flex;
		}

		font-family: Archivo Black;
	}

	.top-percent-styled {
		display: flex;
		justify-content: center;

		.money-box {
			color: black;
			font-size: 100px;
			font-style: normal;
			font-weight: 400;
			line-height: 120px;
			/* 80% */
			font-family: Archivo Black;

			&.isLarge {
				font-size: 80px;
			}
		}

		.money-content {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			transform: translateY(-15px);
			font-family: Archivo Black;

			.top {
				display: flex;
				align-items: center;

				.money-suffix {
					color: black;
					font-size: 25px;
					font-style: normal;
					font-weight: 700;
				}

				.money-off {
					color: black;
					font-family: Playfair Display;
					font-size: 50px;
					font-style: normal;
					font-weight: 700;
					line-height: 55px;
					/* 100% */
				}
			}

			.coupon-name {
				color: black;
				font-size: 25px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				text-transform: uppercase;
			}

			&.isLarge {
				transform: none;
				padding-bottom: 18px;
				padding-top: 5px;

				.coupon-name {
					font-size: 22px;
				}
			}
		}
	}

	.title-styled {
		color: black;
		font-size: 25px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-transform: uppercase;
		text-align: center;
	}

	.top-money-styled {
		display: flex;
		justify-content: center;

		.dollar-box {
			color: black;
			font-family: Poppins;
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: 40px;

			/* 100% */
			&.isLarge {
				transform: translateY(20px);
			}
		}

		.money-box {
			color: black;
			font-size: 150px;
			font-style: normal;
			font-weight: 400;
			line-height: 120px;
			/* 80% */
			font-family: Archivo Black;

			&.isLarge {
				font-size: 80px;
			}
		}

		.money-content {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			transform: translateY(-15px);
			font-family: Archivo Black;

			.top {
				display: flex;
				align-items: center;

				.money-suffix {
					color: black;
					transform: translateY(-3px);
					font-size: 25px;
					font-style: normal;
					font-weight: 700;
					text-decoration-line: underline;
					font-family: Poppins;
				}

				.money-off {
					color: black;
					font-family: Playfair Display;
					font-size: 50px;
					font-style: normal;
					font-weight: 700;
					line-height: 55px;
					/* 100% */
				}
			}

			.coupon-name {
				color: black;
				font-size: 20px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
				text-transform: uppercase;
			}

			&.isLarge {
				transform: none;
				padding-bottom: 18px;
				padding-top: 5px;

				.coupon-name {
					font-size: 18px;
				}
			}
		}
	}
}

.loader-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.302);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: $zindex-fixed + 2;
}

.spinner {
	border: 4px solid rgba(255, 255, 255, 0.744);
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border-left-color: #09f;
	border-width: 6px;
	animation: spin 1s ease infinite;

	&.small-size {
		width: 40px;
		height: 40px;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.input_kit {
	margin-bottom: 15px;
	margin: 10px 0px;

	&.input_search {
		display: flex;
		align-items: center;
		position: relative;

		.prefix_icon {
			position: absolute;
			left: 0;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		input {
			padding-left: 40px;
			border-width: 1px;
		}
	}

	&.note {
		label {
			margin-bottom: 10px;
		}
	}

	label {
		margin-bottom: 5px;
		color: black;
		font-size: 14px;

		label {
			color: red;
		}
	}

	input,
	textarea {
		width: 100%;
		padding: 10px;
		margin: 0;
		border: 2px solid #ddd;
		border-radius: 4px;
		box-sizing: border-box;
		font-size: 14px;
		color: #1D2129;

		&::placeholder {
			color: #ccc;
		}

		&:focus {
			outline: none;
			border-color: #0085E7;
		}
	}

	input.none-disabled-style {
		&:disabled {
			background-color: #fff;
			border: 2px solid #ddd;
			color: #1D2129;
		}
	}
}

.button {
	padding: 4px 8px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 18px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid transparent;
	outline: none;
	margin-right: 5px;

	&.btn-next:disabled {
		background-color: #bdbdbd;
		color: #7B7B7B;
	}

	&:focus,
	&:active {
		outline: none;
		border: none;
	}
}

.btn-back {
	background-color: #f0f0f0;
	color: #7B7B7B;

	&:hover {
		background-color: darken(#f0f0f0, 10%);
	}
}

.btn-next {
	background-color: #0085E7;
	color: #fff;
	border-color: #ffffff;

	&:hover {
		background-color: lighten(#0085E7, 10%);
	}
}

.calendar_kit {
	.react-calendar__navigation {
		display: flex;
		flex: 1;
	}

	.text_year {
		font-size: 12px;
		color: #686868;
	}
}

.search-box {
	display: flex;
	align-items: center;
	padding: 0px 10px;
	border: 1px solid #ddd;
	border-radius: 8px;
	background-color: white;

	input[type="search"] {
		flex-grow: 1;
		border: none;
		padding: 10px;
		outline: none;
		font-size: 14px;
		font-weight: 300;

		&::placeholder {
			color: #999;
		}

		&:focus {
			box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
		}
	}
}

.header-nav {
	background-color: #fff;
	display: flex;
	align-items: center;
	padding: 16px;

	p {
		margin: 0;
		padding: 0;

		&.title {
			flex: 1;
			color: #1D2129;
			text-align: center;

			/* Headline/H6 */
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;
			/* 24.75px */
		}
	}

	button {
		box-shadow: none;
		outline: none;
		border: none;
		background: transparent;
		padding: 0;

		&.back-button {
			&:disabled {
				opacity: 0;
			}
		}
	}

}

.page-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #fff;

	.scroll-container {
		flex: 1;
		position: relative;
		background-color: #F3F3F3;

		.scroll-div {
			position: absolute;
			inset: 0;
			overflow: auto;
			overscroll-behavior: none;
			padding-bottom: 20vh;
		}
	}
}

.booking_success {
	padding: 0px 15px 20px 15px;

	.booking_success_header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;

		span {
			font-size: 14px;
		}

		p {
			font-size: 18px;
			color: #1D2129;
			margin-bottom: 20px;
		}

	}

	.date {
		font-size: 16px;
		font-weight: 500;
		padding-top: 5px;
	}

	.booking_success__staff_info {
		padding: 10px 0px;
		border-width: 0px;
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-color: #000;
		border-style: dashed;
		margin-bottom: 5px;

		p {
			color: #000;
			font-size: 16px;
			font-weight: 400;
		}

		span {
			color: #0085E7;
			font-size: 10px;
			margin-left: 5px;
		}
	}

	.booking_success__services_row {
		padding: 3px 0px;

		p {
			font-size: 15px;
			color: #1D2129;
			font-weight: 300;
		}

		span {
			font-size: 15px;
			color: #1D2129;
			font-weight: 300;
		}
	}

	.booking_success__note_title {
		margin-top: 5px;
		border-width: 0px;
		border-top-width: 1px;
		border-color: #000;
		border-style: dashed;
		padding: 5px 0px;
		color: #000;
		font-size: 15px;
	}

	.booking_success__note {
		text-align: right;
		font-size: 15px;
	}

	button {
		width: 100%;
		background-color: #007bff;
		color: #fff;
		text-align: center;
		padding: 10px;
		border-radius: 5px;
		border: none;
		font-size: 18px;
		margin-top: 30px;
		cursor: pointer;

		&:hover {
			background-color: darken(#007bff, 10%);
		}
	}
}

.empty_kit {
	.empty-image {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.empty-description {
		text-align: center;
		overflow: hidden;
		color: #1D2129;
		text-overflow: ellipsis;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		line-height: normal;
	}
}

.shop_info_kit {
	border-bottom: 1px solid #C9CDD4;
	background: #FFF;
	display: flex;
	flex-direction: column;
	gap: 0px;
	padding: 8px 0;

	p {
		margin: 0;
		padding: 0;
	}

	.shop_info_kit__shop_name {
		color: #1D2129;
		text-align: center;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.shop_info_kit__shop_info {
		.shop_info_kit__text {
			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			/* 166.667% */
		}
	}

	.shop_info_kit__ratings {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.overview_kit {
	.overview-custom-nav.nav-tabs {
		.nav-item {

			.nav-link {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 22px */
				text-transform: uppercase;
				display: flex;
				align-items: center;
				justify-content: center;
				border-width: 2px;

				&.active {
					color: #0085E7;
					font-weight: 500;
				}
			}
		}
	}

	.overview_kit__summary {
		.overview_kit__summary_report_date {
			p {
				margin: 0;
				padding: 0;
			}

			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			/* 125% */
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;

			&.week {
				flex-direction: column;

				.title {
					color: #1D2129;
					text-align: center;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					/* 125% */
				}

				.picker {
					gap: 8px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #1D2129;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
				}
			}

			&.range {
				color: #1D2129;
				text-align: center;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				/* 125% */
			}

		}

		.overview_kit__summary_summary_result {
			color: #686868;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			/* 142.857% */
			text-align: center;

			span {
				color: #1D2129;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}

	.overview_kit__gross-sale {
		background-color: #fff;
		padding: 16px;

		.title-gross-sale {
			color: #0085E7;
			text-align: center;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			margin-bottom: 16px;
			/* 100% */
		}

		.group-gross-sale-summary {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			/* 100% */
			margin-bottom: 16px;
			gap: 4px;

			.hight-light {
				color: #00B52B;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				/* 111.111% */
			}

		}

		.group-gross-sale-detail {
			border-radius: 5px;
			background: #ECF4FE;
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 8px;
			margin-bottom: 8px;

			.group-gross-sale-detail_title {
				color: #686868;
				text-align: center;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
				/* 125% */
			}

			.group-gross-sale-detail-item {
				display: flex;

				.title {
					flex: 1;
					color: #686868;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
				}

				.value {
					display: flex;
					align-items: center;
					gap: 8px;
					color: #1D2129;
					text-align: right;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
					/* 125% */
				}
			}

			&.service-detail {
				.group-gross-sale-detail-item {
					.title {
						color: #1D2129;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}
			}
		}
	}
}

.week_picker_kit {
	.react-datepicker__month-container {
		.react-datepicker__month {

			.react-datepicker__day--selected,
			.react-datepicker__day--highlighted {
				border-radius: 0.3rem;
				background-color: #216ba5;
				color: #fff;
			}
		}
	}
}

.custom-nav-light.nav-tabs {
	.nav-item {
		.nav-link {
			color: #1D2129;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 137.5%;
			/* 22px */
			text-transform: uppercase;
			display: flex;
			align-items: center;
			justify-content: center;
			border-width: 2px;

			&.active {
				color: #0085E7;
				font-weight: 500;
			}
		}
	}
}

.date_picker_kit {
	.date_picker_kit_report_date {
		p {
			margin: 0;
			padding: 0;
		}

		color: #1D2129;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		/* 125% */
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		&.week {
			flex-direction: column;

			.title {
				color: #1D2129;
				text-align: center;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 125% */
			}

			.picker {
				gap: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}

		&.range {
			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			/* 125% */
		}

	}

	.date_picker_kit_from_to {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.date_picker_kit_summary_result {
		color: #686868;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		/* 142.857% */
		text-align: center;

		span {
			color: #1D2129;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.date_picker_appointment {
		display: flex;
		flex: 1;
		justify-content: space-between;

		span {
			color: #1D2129;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		&.week {
			flex-direction: column;

			.label {
				flex: 1;
				text-align: left;
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 125% */
			}

			.from_to_date {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;

				/* 125% */
				span {
					color: #1D2129;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 20px;
				}
			}
		}
	}
}

.bottom_sheet_spring_kit {
	.bottom_sheet_spring_kit__header {
		display: flex;
		align-items: center;

		.button-btn {
			padding: 0;
			background: none;
			box-shadow: none;
			outline: none;
			border: none;
			width: 58px;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;

			/* 22px */
			&.btn-close {
				color: #686868;
			}

			&.btn-ok {
				color: #0C719C;
				text-align: right;

				&:disabled {
					opacity: 0.7;
				}
			}
		}

		.title {
			margin: 0;
			flex: 1;
			color: #1D2129;
			text-align: center;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;
			/* 24.75px */
		}
	}
}

.dropdown__staff_input_kit {
	.btn-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 40px;
		padding: 8px 16px;
		gap: 16px;
		flex-shrink: 0;
		border-radius: 5px;
		border: 1px solid #C9CDD4;
		background: #fff;

		span {
			flex: 1;
			text-align: left;
			color: #86909C;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;

		}
	}
}

.dropdown__staff_input_container_bs {
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

.dropdown__staffs {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	; // Adjust size as necessary
	gap: 6px;
	justify-items: center;
	align-items: center;

	.dropdown__staff {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 85px;
		height: 85px;
		padding: 5px;
		border: none;
		outline: none;
		cursor: pointer;
		background-color: #E5E6EB;
		border-radius: 10px;

		&.active {
			background-color: #ECF4FE;
			border: 1px solid #0085E7;
		}

		.outbox_staffAvatar {
			position: relative;

			.tick {
				position: absolute;
				top: 15px;
				left: 15px;
			}
		}

		img.staffAvatar {
			width: 45px;
			height: 45px;
			object-fit: cover;
			border-radius: 50%;
			margin-bottom: 5px;
			background: #ccc;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
			margin-bottom: 5px;
			font-size: 14px;
		}

	}


	&.list-items {
		display: flex;
		flex-direction: column;
		background: #F6F7FC;

		.dropdown__staff {
			display: flex;
			flex-direction: row;
			gap: 8px;
			width: 100%;
			height: unset;
			background-color: unset;
			padding: 6px 4px;
			position: relative;
			border: 1px solid transparent;

			.dropdown__staff_name {
				flex: 1;
				text-align: left;
				color: #232F3E;
			}

			&.active {
				background-color: #ECF4FE;
				border: 1px solid #0085E7;
			}

			.outbox_staffAvatar {
				position: relative;

				.tick {
					position: absolute;
					right: 0;
				}
			}

			img.staffAvatar {
				width: 32px;
				height: 32px;
				background: #ccc;
				margin-bottom: 0;
			}
		}
	}
}

[data-rsbs-header] {
	box-shadow: none;
}

[data-rsbs-scroll] {
	background-color: #F6F7FC;
}

.filter_option_kit {
	.btn-filter {
		padding: 0;
		background: transparent;
		border: none;
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.filter_option_kit_container_bs {
	.filter_option_kit_container_wrap {
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-bottom: 48px;

		.filter_option_kit_items {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.filter_option_kit_item {
				background-color: transparent;
				padding: 8px 4px;
				border: 1px solid transparent;
				display: flex;
				align-items: center;

				span {
					flex: 1;
					text-align: left;
					color: #1D2129;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;
					/* 19.25px */
				}

				&.active {
					background-color: #ECF4FE;
					border: 1px solid #0085E7;
				}
			}
		}
	}
}

.appointment_range_date_picker {
	.date_picker_kit_report_date {
		justify-content: unset;

		span {
			flex: 1;
			text-align: left;
		}
	}

	.date_picker_kit_from_to {
		justify-content: space-between;
	}
}

.appointments_container {
	padding: 0 16px;

	.counting_label {
		color: #1D2129;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
		margin-bottom: 16px;
	}


	.appointments_groups {
		.appointments_group {
			margin-bottom: 16px;

			.date_label {
				color: #686868;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 8px;
			}

			.appointments_list {
				gap: 8px;
				display: flex;
				flex-direction: column;

				.appointment_item {
					display: inline-flex;
					padding: 12px 16px;
					flex-direction: column;
					align-items: flex-start;
					gap: 4px;
					width: 100%;
					border-radius: 10px;
					border: 1px solid #86909C;
					background: #F6F7FC;

					.appointment_item_time {
						color: #1D2129;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
					}

					.appointment_item_ticket_number {
						color: #0C719C;
						text-align: right;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
					}

					.text-normal {
						color: #545960;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
					}

					.appointment_item_staff_box {
						gap: 12px;

						.staff_name {
							color: #1D2129;
							font-family: Poppins;
							font-size: 18px;
							font-style: normal;
							font-weight: 600;
							line-height: 137.5%;
							text-transform: capitalize;
						}

						.staff_request {
							display: flex;
							padding: 1px 2px;
							justify-content: center;
							align-items: center;
							gap: 10px;
							border-radius: 5px;
							background: #ECF4FE;

							span {
								color: #0085E7;
								font-family: Poppins;
								font-size: 10px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}

						}
					}

					.appointment_item_customer {
						gap: 4px;

						.customer_phone,
						.customer_name {
							color: #0C719C;
							font-family: Poppins;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 137.5%;
							/* 22px */
						}
					}

					.appointment_item_service_item {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: #686868;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
						/* 16.5px */
					}
				}

			}
		}
	}
}


.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.summary_report_kit {
	.summary_report_kit_container {
		padding-right: 16px;
		padding-left: 16px;
	}

	;

	.summary_report_kit_info {
		margin-top: 16px;
		padding-bottom: 16px;
	}

	.section_title {
		color: #1D2129;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.section_divider {
		margin: 8px 0;
		border-bottom: 1px solid #E5E6EB;
	}

	.section_spacing {
		margin-bottom: 16px;
	}

	.summary_report_kit_info_items {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.summary_report_kit_info_item {
		display: flex;
		align-items: center;

		.summary_report_kit_info_item_label {
			color: #1D2129;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.summary_report_kit_info_item_divider {
			flex: 1 1;
			align-self: stretch;
			border-bottom: 2px dashed #E5E6EB;
			margin: 5px;
		}

		.summary_report_kit_info_item_value {
			color: #1D2129;
			text-align: right;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		&.total {
			background: #ECF4FE;
			padding: 4px 0px;

			.summary_report_kit_info_item_label,
			.summary_report_kit_info_item_value {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 125% */
			}
		}
	}
}

.summary_box_kit {
	margin-top: 16px;

	.summary_box_kit_container {
		padding-right: 16px;
		padding-left: 16px;
	}

	;

	.section_title {
		color: #1D2129;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.section_divider {
		margin: 8px 0;
		border-bottom: 1px solid #E5E6EB;
	}

	.section_spacing {
		margin-bottom: 16px;
	}

	.summary_box_kit_info_items {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.summary_box_kit_info_item {
		display: flex;
		align-items: center;

		.summary_box_kit_info_item_label {
			color: #1D2129;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.summary_box_kit_info_item_divider {
			flex: 1 1;
			align-self: stretch;
			border-bottom: 2px dashed #E5E6EB;
			margin: 5px;
		}

		.summary_box_kit_info_item_value {
			color: #1D2129;
			text-align: right;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		&.total {
			background: #ECF4FE;
			padding: 4px 0px;

			.summary_box_kit_info_item_label,
			.summary_box_kit_info_item_value {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 125% */
			}
		}
	}
}


.employee_salaries_kit {
	.employee_salaries_kit_container {
		padding-right: 16px;
		padding-left: 16px;
	}

	.date_picker_container {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.employee_report_table {
		table {
			width: 100%;

			thead {
				border-bottom: 1px solid #E5E6EB;

				th {
					color: #686868;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-transform: uppercase;
					text-align: right;
					width: 120px;

					&.name {
						text-align: left;
						width: 80px;
					}
				}
			}

			tbody {
				td {
					color: #1D2129;
					text-align: right;
					font-family: Poppins;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					&.name {
						text-align: left;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				tr {
					&.total_row {
						td {
							padding: 4px 0px;
							background: var(--Primary-bg, #ECF4FE);
							color: #1D2129;
							text-align: right;
							font-family: Poppins;
							font-style: normal;
							font-weight: 600;
							line-height: normal;

							&.name {
								text-align: left;
							}
						}
					}
				}
			}
		}
	}

	.employee_salary_top_summary {
		background: #fff;
		padding-right: 16px;
		padding-left: 16px;
		padding-bottom: 16px;
		margin-bottom: 8px;

		.employee_salary_summary_box {
			.employee_salary_summary_box_items {
				display: flex;
				gap: 8px;
				margin-bottom: 8px;
			}

			.employee_salary_summary_box_item {
				flex: 1;
				display: flex;
				padding: 12px 16px;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				gap: 4px;
				flex: 1 0 0;
				border-radius: 5px;
				background: #E1E9F3;
				box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.15);

				span.label {
					color: #0C719C;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: -0.28px;
					text-transform: uppercase;
				}

				span.value {
					color: #1D2129;
					text-align: right;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}

			.employee_salary_label {
				display: flex;
				padding: 8px 16px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				border-radius: 5px;
				background: #0C719C;
				box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.15);

				span.label {
					color: #FFF;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.48px;
					text-transform: uppercase;
				}

				span.value {
					color: #FFF;
					text-align: right;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
			}
		}
	}

	.employee_salaries_kit_staffs_group {
		background: #FFF;
		margin-bottom: 8px;
		padding: 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		// padding-bottom: 10vh;

		.filter_group {
			display: flex;
			gap: 16px;
			align-items: center;
			flex-shrink: 0;
			margin-bottom: 16px;

			.search-box {
				flex: 1;

				input {
					width: 100%;
				}
			}

			.btn-sort {
				background-color: transparent;
				box-shadow: none;
				border: none;
				outline: none;
			}
		}

		.employee_salaries_kit_staffs {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.employee_salaries_kit_staff_item {
				display: inline-flex;
				padding: 12px 8px;
				flex-direction: column;
				align-items: center;
				gap: 8px;
				border-radius: 5px;
				border: 1px solid #C9CDD4;
				background: #F6F7FC;
				zoom: 0.95;

				span.staff_name {
					flex: 1;
					color: #1D2129;
					font-family: Poppins;
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				div.box_CH_CA {
					display: flex;
					padding: 1px 2px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					align-self: stretch;
					background: #86909C;

					span {
						color: #FFF;
						text-align: center;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}
				}

				div.detail_item {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					gap: 2px;
					align-self: stretch;
					border-bottom: 1px solid #E5E6EB;
					zoom: 1.1;

					span.label {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.28px;
						text-transform: uppercase;
					}

					span.sub_label {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: #1D2129;
						text-align: right;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 12px;
						font-style: italic;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.24px;
					}

					span.value {
						display: -webkit-box;
						width: 100px;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;
						color: #1D2129;
						text-align: right;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.28px;
						text-transform: uppercase;
					}

					span.total_label {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.32px;
						text-transform: uppercase;
					}

				}

				div.payment_reports {
					display: flex;
					align-items: center;
					gap: 4px;
					background: #E5E6EB;
					width: 100%;

					.payment_report_item {
						flex: 1;
						display: flex;
						padding-left: 4px;
						flex-direction: column;
						justify-content: space-between;
						align-items: flex-start;
						border-left: 1px solid #F6F7FC;

						span.label {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							align-self: stretch;
							overflow: hidden;
							color: #1D2129;
							text-overflow: ellipsis;
							font-family: Poppins;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}

						span.value {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 1;
							align-self: stretch;
							overflow: hidden;
							color: #1D2129;
							text-overflow: ellipsis;
							font-family: Poppins;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}
					}
				}
			}
		}


	}
}

.header_page_kit {
	position: relative;

	.header_title {
		color: #FFF;
		text-align: center;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 137.5%;
		white-space: nowrap;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.marketing_report_kit {
	.marketing_report_kit_container {}

	.btn-nav {
		outline: none;
		background-color: transparent;
		border: none;
	}

	.divider {
		background-color: #E5E6EB;
		height: 1px;
		width: 100%;
		margin-top: 16px;
		margin-bottom: 12px;
	}

	.marketing_report_kit_content_section {
		background-color: #fff;
		padding: 16px;
		margin-bottom: 8px;

		.marketing_report_kit_content_section_title {}
	}

	.marketing_report_kit__review_section {
		.rating {
			display: flex;
			align-items: center;
			gap: 8px;

			.star_rating_label {
				color: #0C719C;
				text-align: center;
				font-family: Poppins;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
				/* 100% */
			}

			.star_rating_counting {
				color: #686868;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 22px */
			}

			.review_label {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}
		}
	}

	.section_title {
		margin-bottom: 8px;
		color: var(--Neutral_09, #1D2129);
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
	}

	.marketing_report_kit__sub_section_cards {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.marketing_report_kit__sub_section_card {
		display: flex;
		padding-bottom: 8px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		border-radius: 5px;
		border: 1px solid #E5E6EB;
		background: #fff;

		.card_header {
			display: flex;
			padding: 8px 16px;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;
			border-radius: 5px 5px 0px 0px;
			border-bottom: 1px solid #E5E6EB;
			background: #F6F7FC;
		}

		.card_title {
			color: #686868;
			flex: 1;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			/* 171.429% */
			text-transform: uppercase;
		}

		.card_body {
			display: flex;
			align-items: center;
			width: 100%;

			.card_item {
				flex: 1;
				display: flex;
				align-self: stretch;
				align-items: center;
				justify-content: center;
				gap: 8px;

				.card_item_content {
					align-self: stretch;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.card_item_value {
						color: #1D2129;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}

					.card_item_label {
						color: #686868;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-transform: uppercase;
					}
				}
			}

			.card_item_divider {
				width: 1px;
				background-color: #E5E6EB;
				align-self: stretch;
			}
		}
	}
}

.review_comment_kit {
	padding: 16px;

	.review_comment_kit_rating {
		display: flex;
		background-color: #fff;
		padding: 16px;
		width: 100%;
		border-radius: 5px;
		background: #FFF;
		margin-bottom: 24px;

		.divider {
			width: 1px;
			align-self: stretch;
			background-color: #E5E6EB;
		}

		&_total_box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.total_text {
				color: #1D2129;
				text-align: center;
				font-family: Poppins;
				font-size: 32px;
				font-style: normal;
				font-weight: 600;
				line-height: 100%;
				/* 32px */
			}

			.counting_text {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}
		}

		&_star_details {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}

		&_star_detail_item {
			display: flex;
			align-items: center;
			gap: 4px;

			&_label {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}

			.progress {
				flex: 1;
				background-color: #E5E6EB;
				height: 4px;
				margin: 0;

				.progress-bar {
					background-color: #FFC31F;
				}
			}

			&_counting {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}

		}
	}

	.review_label {
		color: #1D2129;
		font-family: Poppins;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 137.5%;
		margin-bottom: 16px;
	}

	.review_comment_kit_reviews {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&_review {
		.review_comment_kit_review_item {
			display: inline-flex;
			padding: 8px 16px;
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;
			border-radius: 5px;
			background: #FFF;

			&_customer_info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-self: stretch;
			}

			&_customer_name {
				color: #1D2129;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 137.5%;
			}

			&_customer_phone {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
			}

			&_create_date {
				color: #86909C;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				margin-top: 4px;
			}

			&_review_id {
				color: #0C719C;
				text-align: right;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 137.5%;
				/* 19.25px */
			}

			&_staff_label {
				color: #86909C;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}

			&_staff_avatar {
				width: 20px;
				height: 20px;
				border-radius: 20px;
				background-color: #E3E3E8;
				background-size: contain;
			}

			&_staff_name {
				color: #1D2129;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}

			&_comment {
				color: #1D2129;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}

			&_services {
				color: #686868;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 16.5px */
			}
		}
	}
}

.drop_down_tag_kit {
	.btn-dropdown {
		box-shadow: none;
		outline: none;
		border: none;
		display: inline-flex;
		padding: 4px 16px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 100px;
		background-color: #C9E0FD;

		span {
			color: #0C719C;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		&.blue {
			background-color: #0085E7;

			span {
				color: #FFF;
			}
		}
	}
}

.marketing_report_coupon_kit {
	.marketing_report_coupon_kit_container {
		padding-right: 16px;
		padding-left: 16px;
	}

	.coupon_section {
		padding-top: 16px;
		padding-bottom: 12px;
		background-color: #fff;
		margin-bottom: 8px;
	}

	.marketing_report_coupon_kit_dashboard {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.profit_box {
			display: flex;
			padding: 12px 16px;
			justify-content: space-between;
			align-items: center;
			border-radius: 5px;
			background: #0C719C;
			margin-top: 8px;

			.profit_label {
				color: #FFF;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 137.5%;
			}

			.profit_value {
				color: #FFF;
				font-family: Poppins;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 137.5%;
			}
		}

		.detail_boxes {
			display: flex;
			gap: 8px;

			.detail_box_item {
				display: flex;
				padding: 4px 8px;
				flex-direction: column;
				align-items: flex-end;
				flex: 1 0 0;
				border-radius: 10px;
				border: 1px solid #A9D0F6;
				background: #E9F4FF;

				&_label {
					color: #686868;
					text-align: center;
					font-family: Poppins;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;
					align-self: flex-start;
				}

				&_value {
					color: #1D2129;
					text-align: center;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 137.5%;
					align-self: flex-start;
				}
			}

		}
	}

	&_redeem_history {
		.redeem_history_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;

			span.title {
				color: #1D2129;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 137.5%;
				/* 24.75px */
			}
		}

		.redeem_history_items {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.redeem_history_item {
				display: flex;
				padding: 8px 16px;
				flex-direction: column;
				align-items: flex-start;
				border-radius: 5px;
				background: #F6F7FC;

				.customer_info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					&_name {
						color: #1D2129;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
					}

					&_phone {
						color: #686868;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
						/* 16.5px */
					}
				}

				.ticket_info {
					display: flex;
					align-items: center;
					gap: 8px;
					margin-bottom: 12px;

					&_id {
						color: #0C719C;
						text-align: right;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
						/* 19.25px */
					}

					&_create_date {
						color: #86909C;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
						/* 16.5px */
					}
				}

				.coupon_info {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: space-between;

					&_message {
						color: #1D2129;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
						/* 19.25px */
					}

					&_amount,
					&_price {
						height: 20px;
						display: flex;
						padding: 0px 4px;
						align-items: flex-end;
						gap: 10px;
						background-color: transparent;
					}

					&_amount {
						color: #1D2129;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
					}

					&_price {
						color: #FFF;
						text-align: right;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 137.5%;
						background: var(--Functional_03, #F00);
					}
				}
			}
		}
	}

	.dropdown_filter {
		button {
			display: flex;
			flex-direction: row;
			gap: 8px;

			span {
				color: #0C719C;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			background-color: transparent;
			border: none;
			box-shadow: none;
			outline: none;
		}
	}
}

.staff_list_ui_kit {
	.staff_list_ui_kit_container {
		padding: 0 16px;
	}

	.staff_list_ui_kit_date_picker {
		span {
			color: #686868;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
		}
	}

	.staff_list_ui_kit_staffs {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.staff_list_ui_kit_staff_item {
		display: flex;
		padding: 12px 8px;
		align-items: center;
		border-radius: 5px;
		border: 1px solid #E5E6EB;
		background: #F6F7FC;

		.staff_list_ui_kit_staff_item_avatar_group {
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: center;

			.avatar_img {
				width: 80px;
				height: 80px;
				border-radius: 80px;
				background: rgba(0, 0, 0, 0.2);
			}

			.staff_id {
				display: flex;
				padding: 2px 8px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				border-radius: 5px;
				background: #86909C;

				span {
					color: #fff;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 137.5%;
				}
			}
		}

		.staff_list_ui_kit_staff_item_detail {
			flex: 1;
			padding-left: 8px;
			display: flex;
			flex-direction: column;
			gap: 8px;

			.staff_list_ui_kit_staff_item_detail_info {
				display: flex;
				align-items: center;

				.staff_name_group {
					flex: 1;
					display: flex;
					flex-direction: column;

					.staff_name {
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
						text-transform: uppercase;
					}

					.staff_clock_in {
						color: #686868;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
				}

				.staff_info_calling_icon {
					display: flex;
					width: 36px;
					height: 36px;
					padding: 4px;
					justify-content: center;
					align-items: center;
					border: none;
					box-shadow: none;
					outline: none;
					gap: 10px;
					flex-shrink: 0;
					border-radius: 100px;
					background: #0085E7;
				}
			}

			.staff_list_ui_kit_staff_item_detail_dashboards {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			.staff_list_ui_kit_staff_item_detail_dashboard {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				background-color: #fff;

				.staff_list_ui_kit_staff_item_detail_dashboard_item {
					display: flex;
					width: 73px;
					padding: 0px 16px 0px 8px;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					gap: 2px;

					span {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}

					span.label {
						overflow: hidden;
						color: #686868;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 137.5%;
						/* 16.5px */
						text-transform: uppercase;
					}

					span.value {
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 600;
						line-height: 137.5%;
					}

					&.bold {
						background: #C9CDD4;
					}
				}
			}
		}
	}
}

.date_picker_label_custom {
	display: flex;
	flex: 1;
	justify-content: space-between;

	span {
		color: #686868;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px;
		/* 125% */
	}

	span.highlight {
		color: #1D2129;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}

	&.week {
		flex-direction: column;

		.label {
			flex: 1;
			text-align: left;
			color: #1D2129;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;
			/* 125% */
		}

		.from_to_date {
			color: #1D2129;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;

			/* 125% */
			span {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
			}
		}
	}
}

.empty-data-box {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30vh;

	.text-empty-data {
		color: #686868;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 8px;
		text-align: center;
	}
}

.ticket_by_staff_ui_kit {
	background-color: #fff;

	.ticket_by_staff_ui_kit_filter {
		padding: 16px;
		padding-bottom: 0;
	}

	.ticket_by_staffs_items {
		display: flex;
		flex-direction: column;
		gap: 8px;
		background-color: #fff;
		margin-top: 8px;
		padding: 0 16px;
		padding-bottom: 10vh;

		.ticket_by_staffs_item {
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding: 8px;
			align-items: flex-start;
			border-radius: 5px;
			border: 1px solid #E5E6EB;
			background: #F6F7FC;

			div.row_info {
				display: flex;
				width: 100%;

				.info {
					flex: 1;
				}

				.ticket_number {
					display: flex;
					padding: 2px 4px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					border-radius: 5px;
					background: #1D2129;
					margin-right: 4px;

					span {
						color: #FFF;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-transform: uppercase;
					}
				}

				.customer_name {
					color: #1D2129;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;

					/* 19.25px */
					span {
						font-weight: 600;
					}
				}

				.create_date {
					color: #86909C;
					font-family: Poppins;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;
					/* 16.5px */
				}

				.star-ratings {
					margin-right: 10px;

					.star-container {
						align-items: center;
						height: 14px;
						display: inline-flex !important;
					}
				}
			}

			div.row_total {
				display: flex;
				padding: 4px 8px;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 4px;
				align-self: stretch;
				border-radius: 5px;
				background: #FFF;

				span {
					color: #0C719C;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 137.5%;
					/* 22px */
				}
			}

			div.row_services {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				gap: 4px;
				align-self: stretch;

				span {
					align-self: stretch;
					color: #1D2129;
					font-family: Poppins;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 137.5%;
					/* 16.5px */
				}
			}
		}
	}
}

.new-appointment-wrapper-v2 {
	.text-label {
		margin-top: 24px;
		color: #1D2129;
		text-align: center;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: uppercase;
	}

	.container-box {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.input_kit input,
	.input_kit textarea {
		border-width: 1px;
	}

	.new_appointment__categories {
		// background: #E5E6EB;
		display: flex;
		align-items: flex-start;
		align-self: stretch;
		gap: 0;
		grid-gap: 0;

		.button_category {
			white-space: nowrap;
		}

		.new_appointment__category {
			background: #E5E6EB;
			padding: 10px 30px;

			&.active {
				background: #0C719C;
			}
		}
	}

	.new_appointment__services {
		display: flex;
		flex-direction: column;
		gap: 8px;

		.new_appointment__service {
			display: flex;
			padding: 4px 8px;
			align-items: center;
			gap: 4px;
			flex: 1 0 0;
			border-radius: 5px;
			border: 1px solid #C9CDD4;
			background: #FFF;

			.serviceImage {
				width: 64px;
				height: 64px;
				margin: 0;
				margin-right: 4px;
				border: 0.5px solid #C9CDD4;
			}

			.serviceTitle {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				align-self: stretch;
				overflow: hidden;
				color: #1D2129;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			.servicePrice {
				color: #1D2129;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.react-calendar.calendar_kit {
		.react-calendar__month-view__days button.react-calendar__tile {
			height: 35px;
		}
	}

	.new_appointment__time_slots_block {
		.new_appointment__time_slots_label {
			color: #86909C;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.54px;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
	}

	.new_appointment__staffs {
		grid-template-columns: 1fr 1fr 1fr;

		.new_appointment__staff {
			width: 100%;
			height: auto;

			img.staffAvatar {
				width: 65px;
				height: 65px;
			}

			p {
				color: #1D2129;
				text-align: center;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.custom-nav-light.nav-tabs {
		flex-wrap: nowrap;

		.nav-item {
			flex: 1;

			.nav-link {
				position: relative;
				font-size: 14px;

				.icon-tick-placeholder {
					position: relative;
					width: 13px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.icon-tick {
					position: absolute;
					right: -10px;
					transform: translateY(-2px);
				}

				&.success {
					color: #0085E7;
					font-weight: 500;
				}
			}
		}
	}
}

#snackbar {
	visibility: hidden;
	/* Hidden by default. Visible on click */
	min-width: 250px;
	/* Set a default minimum width */
	background-color: #ff9966;
	/* Black background color */
	color: #fff;
	/* White text color */
	text-align: center;
	/* Centered text */
	border-radius: 5px;
	/* Rounded borders */
	padding: 8px 16px;
	/* Padding */
	position: fixed;
	/* Sit on top of the screen */
	z-index: 1;
	/* Add a z-index if needed */
	left: 50%;
	/* Center the snackbar */
	top: 30px;
	transform: translateX(-50%);
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.SUCCESS {
	background-color: #02AB2A;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	font-weight: 700;
}

#snackbar.show {
	visibility: visible;
	/* Show the snackbar */
	/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
	from {
		top: 0;
		opacity: 0;
	}

	to {
		top: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		top: 0;
		opacity: 0;
	}

	to {
		top: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		top: 30px;
		opacity: 1;
	}

	to {
		top: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		top: 30px;
		opacity: 1;
	}

	to {
		top: 0;
		opacity: 0;
	}
}

.shop_info_header_kit {
	padding-top: 8px;
	padding-bottom: 4px;

	.shop_name {
		color: #1D2129;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.address {
		color: #1D2129;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		/* 142.857% */
	}
}


.employee_salaries_kit_v2 {
	.date_picker_container {
		padding: 16px;
	}

	.employee_salaries_kit_staffs_group {
		padding-top: 0;

		.tab-content {
			margin: 0 -16px;

			.date_picker_container {
				.date_picker_kit {
					.date_picker_kit_from_to {
						justify-content: space-between;
					}
				}

				&.month {
					.date_picker_label_custom {
						align-items: center;
						justify-content: center;
						gap: 8px;

						span {
							color: #1D2129;
							text-align: center;
							font-family: Poppins;
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 20px;
							/* 125% */
						}
					}
				}

				&.range {
					.top-label {
						color: #1D2129;
						text-align: center;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px;
						/* 125% */
						margin-bottom: 8px;
					}
				}

			}
		}
	}

	.employee_salaries_kit_summary {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-bottom: 8px;

		.summary-item {
			display: flex;
			align-items: center;

			.summary-label {
				color: #1D2129;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.divider-dotted {
				flex: 1;
				align-self: stretch;
				border-bottom: 1px dashed #E5E6EB;
			}

			.summary-value {
				color: #1D2129;
				text-align: right;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			&.master {
				padding: 4px 0px;
				background: #ECF4FE;

				.summary-label {
					color: #1D2129;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					/* 125% */
				}

				.summary-value {
					color: #1D2129;
					text-align: right;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
					/* 125% */
				}
			}
		}

		.divider-line {
			align-self: stretch;
			height: 1px;
			width: 100%;
			margin-top: 4px;
			border-bottom: 1px solid #E5E6EB;
		}
	}

	.employee_salaries_kit_title_section {
		color: #1D2129;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.employee_salaries_kit_staffs_table {
		thead {
			th {
				color: #686868;
				text-align: right;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				&.name {
					text-align: left;
				}
			}
		}

		tbody {
			td {
				padding-bottom: 4px;
				color: #1D2129;
				text-align: right;
				font-family: Poppins;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				&.name {
					span {
						text-align: left;
						overflow: hidden;
						color: #1D2129;
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 10px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
					}
				}
			}
		}

		.divider-top {
			background-color: #E5E6EB;
			width: 100%;
			height: 1px;
			margin: 8px 0;
			margin-top: 0;
		}

		.divider-bottom {
			background-color: #1D2129;
			width: 100%;
			height: 1px;
			margin: 8px 0;
		}

		tfoot {
			td {
				background: #ECF4FE;
				padding: 4px 0px;
				overflow: hidden;
				color: #1D2129;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 10px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
				/* 200% */
				text-align: right;

				&.name {
					text-align: left;
				}
			}
		}
	}

}

.check-staff-available-wrapper {
	.check-staff-available-wrapper__container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}

	.text-label {
		margin: 0;
		text-align: left;
	}

	.new_appointment__time_slots_label {
		margin-top: 0 !important;
	}

	.group-time-slots {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}

.page-container.detail-appointment-wrapper {
	.scroll-div {
		background-color: #fff;
	}

	.top-divider {
		background-color: #F6F7FC;
		height: 8px;
		width: 100%;
	}

	.status-container {
		background-color: #F6F7FC;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px 0;

		.status-box {
			height: 32px;
			min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 12px;

			span {
				font-size: 20px;
			}

			&.cancel {
				border: 1px solid #F00;
				color: #F00;
			}
		}
	}

	.detail-appointment-content-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-self: stretch;

		.btn-icon {
			padding: 0;
			height: 35px;
			width: 35px;
			display: flex;
			align-items: center;
			justify-content: center;

			img.icon-edit {
				width: 30px;
				height: 30px;
			}

		}

		.detail-appointment-content-group {
			background: #fff;
			display: flex;
			flex-direction: column;
			padding: 8px 16px;
			padding-bottom: 16px;
			align-self: stretch;
			border-bottom: 4px solid #F6F7FC;

			.section-label {
				background-color: #ECF4FE;
				align-self: flex-start;
				border-radius: 5px;
				padding: 0 4px;
				margin-bottom: 8px;

				span {
					color: #0C719C;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}
			}
		}

		.customer-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			align-self: stretch;

			.customer-name {
				align-self: stretch;
				color: #232F3E;
				text-align: center;
				font-family: Poppins;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}

			.customer-phone {
				align-self: stretch;
				color: #686868;
				text-align: center;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.booking-information-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 8px;
			align-self: stretch;

			.line {
				background: #CCD4DC;
				height: 1px;
				align-self: stretch;
			}

			.information-row-content {
				display: flex;
				align-items: center;
				align-self: stretch;
				gap: 16px;

				span {
					color: #686868;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					letter-spacing: 0.32px;

					&.value {
						color: #232F3E;
						font-family: Poppins;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: 137.5%;
						letter-spacing: 0.32px;
					}

					&.label {
						flex: 1;
						text-align: left;
					}
				}
			}
		}

		.staff-container {
			display: flex;
			align-items: center;
			gap: 8px;

			.staff-avatar {
				border-radius: 100px;
				width: 32px;
				height: 32px;
				background-color: #979797;
			}
		}

		.note-container {
			display: flex;
			flex-direction: column;
			align-self: stretch;

			textarea {
				color: #232F3E;
			}
		}

		.service-top-action-container {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
			align-self: stretch;
			justify-content: space-between;

			.section-label {
				margin: 0;
				align-self: center;
			}

			.btn-actions {
				display: flex;
				align-items: center;
				gap: 16px;
			}
		}

		.service-group-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 8px;

			.service-item-container {
				padding: 4px 8px;
				background: #FFFFFF;
				border: 1px solid #C9CDD4;
				border-radius: 5px;
				text-align: left;
				display: flex;
				align-items: center;

				.service-box {
					flex: 1;
				}

				.name {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					color: #1D2129;
					text-overflow: ellipsis;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				.price {
					color: #1D2129;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
				}

				.btn-icon {
					width: 30px;
					height: 30px;
				}
			}
		}
	}

	.sticky-footer-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 8px;
		padding: 8px 16px;
		background-color: #fff;
		border-top: 1px solid #E5E5E5;

		&.add-minus-container {
			display: flex;
			align-items: center;

			.btn {
				flex: 1;
				margin: 0;
				padding: 0.625rem 0;
			}

			.duration-label {
				flex: 0.7;
				text-align: center;
			}
		}

		.btn {
			width: 100%;

			&.btn-cancel,
			&.btn-delete {
				border: 1px solid #6c757d;
				color: #6c757d;
			}

			&.btn-delete {
				border: 1px solid #F00;
				color: #F00;
			}
		}
	}
}

.modal-reason-container {
	display: flex;
	padding: 16px;
	flex-direction: column;
	gap: 8px;

	textarea {
		color: #273D52;
	}

	.suggestions {
		display: flex;
		align-items: center;
		gap: 8px;

		.suggestion-item {}
	}
}

.modal-confirm-kit {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-confirm-box {
		background-color: #fff;
		border-radius: 5px;
		display: flex;
		flex-direction: column;

		.modal-confirm-box-header {
			height: 50px;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			background: rgb(255, 255, 255);
			padding: 8px;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			border-bottom: 1px solid rgb(206, 206, 206);

			span {
				color: rgb(29, 33, 41);
				text-align: center;
				font-family: Poppins;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px;
			}
		}

		.modal-confirm-box-body {
			height: 60px;
			padding: 16px;
		}

		.modal-confirm-box-footer {
			display: flex;
			padding: 16px;
			padding-top: 0;

			button {
				flex: 1;

				&.outline {
					border: 1px solid #6c757d;
					color: #6c757d;
				}
			}
		}
	}
}

.staff-home-dashboard-kit {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	scrollbar-width: none;

	/* Firefox 64 */
	&::-webkit-scrollbar {
		width: 0px;
		height: 8px;
		background-color: #F5F5F5;
	}


	.bg-header-home {
		background-color: #3A317D;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.staff-header {
		padding-top: 10px;
		align-items: center;
		position: relative;
		z-index: 4;
		margin: 0;
		display: flex;
		align-items: center;
		padding: 0 16px;
		gap: 8px;

		div.staff-name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
			color: #FFF;
			font-family: Poppins;
			font-size: 22px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.staff-nav-tabs-container {
		position: relative;
		z-index: 2;
	}

	.staff-nav-tab-content {
		position: relative;
		z-index: 2;
		flex: 1;

		.tab-pane {
			height: 100%;
		}

		.tab-pane.appointment {
			background-color: #fff;
		}
	}
}

.staff-appointment-calendar-date-picker {
	position: sticky;
	top: 0;
	z-index: 99;
	background: #FFF;

	border-bottom: 1px solid #E5E6EB;

	.date_picker_appointment {
		align-items: center;
		display: flex;
		padding: 8px 16px 8px 16px;
		gap: 8px;
		background: #FFF;
		padding-right: 8px;


		.date_picker_appointment-label {
			color: #232F3E;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.date_picker_appointment-icon {
			display: flex;
			width: 32px;
			height: 32px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			border-radius: 5px;
			background: #E5E6EB;
		}
	}

	.btn-add-new {
		height: 32px;
		width: 32px;
		padding: 0;
		font-size: 20px;
		font-weight: 500;
	}
}

.calendar-ui-kit {
	.calendar-row-time {
		position: absolute;
		z-index: 11;
		height: unset;
		box-shadow: none;
		left: 0;
		top: 0px;
		right: 0px;
		display: flex;
		align-items: center;

		.circle {
			position: sticky;
			left: 80px;
		}

		.box-time {
			width: 80px;
			min-width: 80px;
			max-width: 80px;
			padding-right: 11px;
			position: sticky;
			left: 0;
			background-color: #fff;
			height: 1px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.text-time {
				color: #F00;
				text-align: right;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;

				&.strong {
					color: #232F3E;
					font-weight: 600;
				}
			}
		}

		.time-line {
			background-color: #F00;
			height: 1px;
			flex: 1;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;

		thead {
			th {
				position: sticky;
				top: 0;
				z-index: 12;

				&.fixed-time-td {
					left: 0;
					top: 0;
					z-index: 13;
					background-color: #fff;
				}
			}
		}

		tbody {
			tr {
				position: relative;
			}

			// tr:first-child {
			// 	td {
			// 		box-shadow: inset 0px 0px 0px #CCD4DC;
			// 	}
			// }

			td {
				width: auto;
				height: 60px;
				box-shadow: inset 0 1px 0px #CCD4DC, inset 0px 0px 0px #CCD4DC;

				&.fixed-time-td {
					width: 80px;
					min-width: 80px;
					max-width: 80px;
					background-color: #fff;
					box-shadow: inset 1px 0px 0px #fff;
					position: sticky;
					left: 0;
					z-index: 10;

					.box-time {
						height: 100%;
						padding-right: 0px;
						padding-left: 16px;

						.text-time {
							transform: translateY(-10px) translateX(-10px);
							color: #686868;
							text-align: right;
							font-family: Poppins;
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;

							&.strong {
								color: #232F3E;
								font-weight: 600;
							}
						}
					}

					&.to_09-00-AM_time .box-time .text-time {
						transform: translateX(-10px);
					}
				}

				.box-td {
					height: 100%;
				}
			}
		}
	}

	&.staffView {
		padding-top: 16px;

		table tbody td {
			width: 100%;

			&.fixed-time-td {
				width: 85px;
				min-width: 85px;
				max-width: 85px;

				&.to_09-00-AM_time .box-time .text-time {
					transform: translateY(-10px) translateX(-10px);
				}
			}
		}
	}

	&.adminView {
		background-color: #fff;

		.calendar-row-time .circle {
			position: sticky;
			left: 85px;
		}

		.calendar-row-time .box-time {
			width: 85px;
			min-width: 85px;
			max-width: 85px;
		}

		table thead th {
			background-color: #fff;
			box-shadow: inset 0px -1px 0px #CCD4DC;
			border-right: 1px solid #CCD4DC;

			&.fixed-time-td {
				box-shadow: inset 0px -1px 0px #CCD4DC, inset -1px 0px 0px #CCD4DC;
			}

			.staff-view-header {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;

				img.avatar {
					display: flex;
					width: 32px;
					height: 32px;
					padding: 4px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					display: flex;
					border-radius: 100px;
					background: #FFF;
				}

				span.staff-name {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
					color: #232F3E;
					text-overflow: ellipsis;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					text-transform: uppercase;
				}
			}
		}

		table tbody td {
			min-width: 200px;
			background-color: #fff;
			border-right: 1px solid #CCD4DC;

			&.fixed-time-td {
				width: 85px;
				min-width: 85px;
				max-width: 85px;
				box-shadow: inset -1px 0px 0px #CCD4DC;

				&.to_09-00-AM_time .box-time .text-time {
					transform: translateY(-10px) translateX(-10px);
				}
			}
		}
	}

	.calendar-item {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 2px 0;
		padding-right: 4px;

		.appointment-item-container {
			flex: 1;
			border-radius: 5px;
			background: #EEF4FF;
			border-left: 4px solid #809BCE;
			display: flex;
			flex-direction: column;
			position: relative;

			.appointment-item-container-box {
				gap: 4px;
				padding: 4px 0px 0px 8px;
				flex-shrink: 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				overflow: hidden;
			}

			.top-view-container {
				display: flex;
				padding-right: 8px;
				justify-content: space-between;
				align-items: center;
				align-self: stretch;

				.time-text {
					display: flex;
					align-items: center;
					gap: 2px;
					flex: 1 0 0;

					span {
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						flex: 1 0 0;
						overflow: hidden;
						color: var(--Neutral_08, #686868);
						text-overflow: ellipsis;
						font-family: Poppins;
						font-size: 12px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}

				.booking-act {
					display: flex;
					align-items: center;
					gap: 4px;

					img.icon {
						height: 20px;
						width: 20px;
					}
				}
			}

			.appointment-customer-time {
				display: flex;
				align-items: center;
				gap: 4px;
			}

			.appointment-customer-name {
				color: #1D2129;
				font-family: Poppins;
				font-size: 18px;
				height: 18px;
				line-height: 18px;
				font-style: normal;
				font-weight: 600;
				text-transform: uppercase;
			}

			.appointment-services {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				flex: 1 0 0;
				overflow: hidden;
				color: #1D2129;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.appointment-note {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				flex: 1 0 0;
				overflow: hidden;
				color: #686868;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

			.appointment-item-duration {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				gap: 24px;
				position: absolute;
				bottom: 0;
				left: 50%;
				z-index: 4;
				transform: translate(-50%, 15px);

				.btn-duration {
					background: transparent;
					border: transparent;
					height: 28px;
					width: 28px;
					padding: 0;

					img.duration-icon {
						height: 100%;
						width: 100%;
					}
				}
			}

			&.disabled {
				background-color: #E5E6EB;
				border-left: 4px solid #686868;

				.appointment-services,
				.appointment-customer-name {
					color: #686868;
				}

				.appointment-item-duration {
					display: none;
				}
			}
		}

		.multiple-appointment-item-container {
			flex: 1;
			border-radius: 5px;
			background: #FFEFEB;
			border-left: 4px solid #F05326;
			display: flex;
			gap: 4px;
			flex-direction: column;
			position: relative;

			.appointment-item-container {
				background: transparent;
				border-bottom: 1px solid #F05326;
				flex: unset;
				border-left: none;
				border-radius: 0;

				&:last-child {
					border-bottom: none;
				}

				.appointment-item-container-box {
					gap: 0;
				}

				.appointment-item-duration {
					display: none;
				}
			}
		}

		.block-hour-item-container {
			background: #E5E6EB;
			gap: 4px;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 0px 4px;
			flex: 1;
			border-radius: 5px;
			border: 1px solid #ababab;

			img.icon {
				width: 24px;
				height: 24px;
			}

			.block-hour-top {
				display: flex;
				align-items: center;
				gap: 8px;
			}

			.block-hour-time {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				flex: 1 0 0;
				overflow: hidden;
				color: #1D2129;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.block-hour-note {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				flex: 1 0 0;
				overflow: hidden;
				color: #686868;
				text-overflow: ellipsis;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}
}

.owner-calendar-kit {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	scrollbar-width: none;

	/* Firefox 64 */
	&::-webkit-scrollbar {
		width: 0px;
		height: 8px;
		background-color: #F5F5F5;
	}

	.date_picker_kit {
		display: flex;
		position: relative;
	}

	.date_picker_appointment {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;
		width: 80px;

		.date_picker_appointment-icon {
			width: 24px;
			height: 24px;
		}

		.date_picker_appointment-label {
			color: #0C719C;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			transform: translateY(2px);
		}
	}

	.fab-overlay {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(220, 220, 220, 0.7);
		visibility: hidden;
		opacity: 0;
		transition: all 0.2s ease-in;
		will-change: opacity;

		&.show {
			visibility: visible;
			opacity: 1;
		}
	}

	.fab-hamburger {
		z-index: 101;
		position: fixed;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		bottom: 20px;
		right: 20px;
		margin: 0;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		.icon-bar {
			display: block;
			background-color: #fff;
			width: 22px;
			height: 2px;
			transition: all 0.3s ease-in-out;

			+.icon-bar {
				margin-top: 4px;
			}
		}

		&.show {
			.icon-bar {
				background-color: #1D2129;
			}

			.wrapper {
				transition: transform 0.4s ease-in-out;
				transform: rotateZ(90deg);
			}

			#one {
				transform: translateY(6px) rotateZ(45deg) scaleX(0.9);
			}

			#thr {
				transform: translateY(-6px) rotateZ(-45deg) scaleX(0.9);
			}

			#two {
				opacity: 0;
			}
		}
	}

	.fab-nav {
		z-index: 102;
		position: fixed;
		bottom: 45px;
		right: 20px;
		margin: 0;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		visibility: hidden;
		opacity: 0;
		cursor: pointer;
		transition: all 0.2s ease-in;

		&.show {
			visibility: visible;
			opacity: 1;
		}
	}

	.icon {
		height: 28px;
		width: 28px;
	}

	.add-new.show {
		transform: translateY(-75%);

	}

	.refresh-page.show {
		transform: translateY(-200%);
	}

	.back-page.show {
		transform: translateY(-325%);
		border: 1px solid #ababab;
		color: #6c757d;
		background-color: #E5E6EB;

		.icon {
			height: 24px;
			width: 24px;
		}
	}

}

.owner-calendar-kit.calendar-staff-FAB {
	position: unset;
}

.customer-report-ui-kit {
	position: fixed;
	inset: 0;
	background: #F6F7FC;
	display: flex;
	flex-direction: column;
	overflow: auto;

	.header-container {
		border-radius: 0px 0px 24px 24px;
		background: #3A317D;
		padding-bottom: 16px;
	}

	.top-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 54px;
		padding: 0px;
		flex-shrink: 0;

		.top-bar-title {
			color: #FFF;
			text-align: center;
			font-family: Poppins;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;
		}

		.btn-action {
			background-color: transparent;
			border: none;
			box-shadow: none;
			outline: none;
			padding: 0 24px;

			img {
				width: 24px;
				height: 24px;
				flex-shrink: 0;
			}
		}
	}

	.top-content-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	.total-customer-container {
		display: flex;
		padding-bottom: 8px;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		align-self: stretch;

		.total-customer-text {
			color: #FFF;
			text-align: center;
			font-family: Poppins;
			font-size: 40px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px;
			letter-spacing: -0.4px;
			align-self: stretch;
		}

		.total-customer-label {
			align-self: stretch;
			color: #DEF5FF;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			letter-spacing: -0.16px;
		}
	}

	.customer-statistics-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		align-self: stretch;
		padding: 0 16px;

		.statistics-group {
			display: flex;
			padding: 0px 8px;
			align-items: flex-start;
			gap: 2px;
			align-self: stretch;

			.statistics-group-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 2px;
				flex: 1 0 0;

				.statistics-group-item-label {
					align-self: stretch;
					color: #809BCE;
					text-align: center;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
				}

				.statistics-group-item-value {
					align-self: stretch;
					color: #FFF;
					text-align: center;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}
		}

		.customer-group {
			display: flex;
			align-items: flex-start;
			gap: 2px;
			align-self: stretch;
			border-radius: 8px;
			background: #FFF;

			button.customer-group-item {
				display: flex;
				padding: 8px 0px;
				flex-direction: column;
				align-items: flex-start;
				gap: 4px;
				flex: 1 0 0;
				align-self: stretch;
				border-radius: 8px;
				border: none;

				&:nth-child(odd) {
					background: #ECF4FE;
				}

				&:nth-child(even) {
					background: #fff;
				}

				.customer-group-item-label {
					align-self: stretch;
					color: #64748B;
					text-align: center;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					letter-spacing: 0.14px;
					flex: 1;
				}

				.customer-group-item-value {
					align-self: stretch;
					color: #0C719C;
					text-align: center;
					font-family: Poppins;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
				}
			}
		}
	}

	.customer-report-ui-kit__content-container {
		flex: 1;
		background: #F6F7FC;
		padding-top: 16px;

		.ph {
			align-self: stretch;
			display: flex;
			flex-direction: column;
			padding-right: 16px;
			padding-left: 16px;
		}

		.input-search-container {
			display: flex;
			align-items: center;
			gap: 8px;
			align-self: stretch;

			.input-search-selector {
				display: flex;
				height: 48px;
				padding: 0px 16px;
				align-items: center;
				gap: 6px;
				flex: 1 0 0;
				border-radius: 5px;
				border: 1px solid #E5E6EB;
				background: #FFF;

				img.search-icon {
					width: 24px;
					height: 24px;
				}

				.input-search {
					flex: 1;
					background: transparent;
					border: none;
					outline: none;
					font-family: Inter;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;

					&::placeholder {
						color: #64748B;
						opacity: 1;
					}

					&::-ms-input-placeholder {
						color: #64748B;
					}

				}

			}

			.filter-button {
				display: flex;
				height: 48px;
				padding: 8px 16px;
				align-items: center;
				gap: 24px;
				border-radius: 5px;
				background: #E5E6EB;
				border: none;

				img.icon-filter {
					display: flex;
					width: 24px;
					height: 24px;
					justify-content: center;
					align-items: center;
				}
			}

			&.focus .input-search-selector {
				border-color: #3A317D;
			}
		}

		table.customer-table {
			align-self: stretch;
			background-color: #F6F7FC;
			position: relative;

			.overlay-loader {
				position: absolute;
				inset: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba(255, 255, 255, 0.4);

				.loader {
					.spinner {
						width: 40px;
						height: 40px;
					}
				}
			}

			thead th {
				color: #0C719C;
				font-family: Poppins;
				padding: 8px 0;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;

				&:first-child {
					padding-left: 16px;
					text-align: left;
				}

				&:last-child {
					padding-right: 16px;
					text-align: right;
				}

				&.text-right {
					text-align: right;
				}

				&.not-returned-days {
					min-width: 65px;
					max-width: 65px;
				}

				&.returned-days {
					min-width: 60px;
					max-width: 60px;
				}

				&.recent-date {
					min-width: 70px;
					max-width: 70px;
				}
			}

			tbody td {
				padding: 12px 0;
				color: #232F3E;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 137.5%;

				&:first-child {
					padding-left: 16px;
					text-align: left;
					padding-right: 4px;
				}

				&:last-child {
					padding-right: 16px;
					text-align: right;
				}

				&.phone {
					min-width: 120px;
					max-width: 120px;
				}

				&.not-returned-days {
					min-width: 65px;
					max-width: 65px;
				}

				&.returned-days {
					min-width: 60px;
					max-width: 60px;
				}

				&.create-date {
					min-width: 110px;
					max-width: 110px;
				}

				&.recent-date {
					min-width: 70px;
					max-width: 70px;
				}

				&.width-sm {
					min-width: 90px;
					max-width: 90px;
				}

				&.text-right {
					text-align: right;
				}

				&.number {
					text-align: right;
				}

				&.customer {
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}

				&.ellipsis .ellipsis-text {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					align-self: stretch;
					overflow: hidden;
					color: #232F3E;
					text-overflow: ellipsis;
				}
			}

			tbody tr:nth-child(even) td {
				background: #EEF4FF;
			}

			tbody tr:nth-child(odd) td {
				background: #fff;
			}

			tfoot .pagination {
				display: flex;
				justify-content: flex-end;
				padding-bottom: 16px;

				.pagination-container {
					display: flex;
					align-items: center;
					gap: 4px;
					padding: 4px;
					padding-right: 12px;

					.pagination-text {
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.pagination-btn {
						width: 40px;
						height: 40px;
						border: 1px solid #E3E3E8;
						border-radius: 6px;
						background: #FCFCFD;

						.action-icon {
							height: 30px;
							width: 30px;
						}

						&:disabled {
							opacity: 0.8;
							background: #eee;
						}
					}
				}
			}
		}

	}

	.customer-groups-list-page.customer-report-ui-kit__content-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}

	.customer-detail-page.customer-report-ui-kit__content-container {
		display: flex;
		flex-direction: column;
		padding-top: 0;

		.customer-detail-tabs {
			display: flex;
			width: 375px;
			height: 48px;
			padding: 0px 16px;
			align-items: flex-end;
			gap: 8px;
			background: #F7F8FA;

			.customer-detail-tab-item {
				display: flex;
				border: none;
				background: #F7F8FA;
				padding: 9px 0px;
				justify-content: center;
				align-items: center;
				flex: 1 0 0;
				align-self: stretch;
				border-bottom: 2px solid transparent;

				&.active {
					border-bottom: 2px solid #0C719C;

					.customer-detail-tab-item-label {
						color: #0C719C;
					}
				}

				.customer-detail-tab-item-label {
					color: #232F3E;
					text-align: center;
					font-family: Poppins;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 137.5%;
				}
			}
		}

		.customer-detail-tab-content {
			display: flex;
			flex-direction: column;

			&.hidden {
				display: none;
			}

			&.show {
				display: flex;
			}
		}

		.customer-detail-tab-content-about-tab {
			display: flex;
			flex-direction: column;
			padding: 24px 16px;
			align-items: flex-start;
			gap: 16px;
			align-self: stretch;
			background: #FFF;

			.statistic-container {
				display: flex;
				align-items: center;
				gap: 16px;
				align-self: stretch;

				.statistic-label {
					color: #232F3E;
					font-family: Poppins;
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 27px;
				}

				img.show-icon {
					display: flex;
					width: 24px;
					height: 24px;
					justify-content: center;
					align-items: center;
				}
			}

			.about-details {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				align-self: stretch;
				gap: 8px;

				.about-detail-item {
					display: flex;
					padding: 4px 0px;
					gap: 8px;
					align-self: stretch;
					border-bottom: 1px solid #E5E6EB;

					img.icon {
						width: 20px;
						height: 20px;
					}

					.about-detail-sub-item {
						display: flex;
						gap: 8px;
						flex: 1 0 0;

						.about-detail-sub-item-label {
							width: 120px;
							color: #686868;
							font-family: Poppins;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 137.5%;
							/* 19.25px */
							letter-spacing: 0.28px;
						}

						.about-detail-sub-item-value {
							color: #232F3E;
							font-family: Poppins;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 137.5%;
							letter-spacing: 0.28px;

							&.phone {
								color: #0C719C;
								font-weight: 600;
							}
						}
					}
				}
			}
		}

		.customer-detail-tab-content-history-tab {
			background: #FFF;
			min-height: 100vh;

			.react-datepicker__input-container {
				padding: 16px;
			}

			.tickets-container {
				display: flex;
				padding: 8px 16px 24px 16px;
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;
				align-self: stretch;
				border-radius: 5px;

				.ticket-label {
					align-self: stretch;
					color: #232F3E;
					font-family: Poppins;
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					line-height: 27px;
					/* 150% */
					letter-spacing: 0.54px;
				}

				.tickets-list {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 8px;
					align-self: stretch;

					.ticket-item {
						display: flex;
						padding: 8px;
						flex-direction: column;
						align-items: flex-start;
						align-self: stretch;
						gap: 8px;
						border-radius: 5px;
						border: 1px solid #E5E6EB;
						background: #F7F8FA;

						.ticket-top-container {
							display: flex;
							align-items: flex-start;
							gap: 8px;
							align-self: stretch;

							.ticket-number {
								display: flex;
								width: 40px;
								height: 40px;
								padding: 2px 4px;
								justify-content: center;
								align-items: center;
								gap: 10px;
								border-radius: 5px;
								background: #232F3E;

								span {
									color: #FFF;
									font-family: Poppins;
									font-size: 16px;
									font-style: normal;
									font-weight: 500;
									line-height: normal;
									text-transform: uppercase;
								}
							}

							.staff-time-container {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: flex-start;
								gap: 2px;
								flex: 1;

								.staff {
									display: flex;
									align-items: center;
									gap: 8px;

									span {
										color: #232F3E;
										font-family: Poppins;
										font-size: 14px;
										font-style: normal;
										font-weight: 400;
										line-height: 137.5%;

										&.bold {
											font-weight: 600;
										}
									}
								}

								span.time {
									color: #64748B;
									font-family: Poppins;
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 137.5%;
									/* 16.5px */
								}
							}

						}

						.ticket-payment {
							display: flex;
							padding: 4px 8px;
							justify-content: space-between;
							align-items: center;
							align-self: stretch;
							background: #FFF;

							.total-text {
								color: #0C719C;
								font-family: Poppins;
								font-size: 16px;
								font-style: normal;
								font-weight: 600;
								line-height: 137.5%;
							}

							img.icon-payment {
								display: flex;
								width: 24px;
								height: 24px;
								justify-content: center;
								align-items: center;
							}

							.ticket-payment-type-card-container {
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 2px;
								color: #232F3E;
								text-align: right;
								font-family: Poppins;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 137.5%;
							}

							.ticket-payment-types {
								display: flex;
								justify-content: flex-end;
								align-items: center;
								gap: 4px;
							}
						}

						.service-ticket-info {
							display: flex;
							padding: 4px 8px;
							flex-direction: column;
							align-items: flex-start;
							gap: 4px;
							align-self: stretch;
							border-radius: 5px;
							background: #FFF;

							.service-text {
								align-self: stretch;
								color: #232F3E;
								font-family: Poppins;
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: 137.5%;
								border-bottom: 1px solid #E5E6EB;
								padding-bottom: 4px;
							}

							.ticket-info-group {
								display: flex;
								align-items: flex-start;
								gap: 24px;
								align-self: stretch;

								.ticket-info-group-item {
									display: flex;
									flex-direction: column;
									align-items: flex-start;
									gap: 2px;
									flex: 1 0 0;
								}

								.ticket-info-content-item {
									display: flex;
									justify-content: space-between;
									align-items: center;
									align-self: stretch;
									color: #232F3E;
									font-family: Poppins;
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 137.5%;
								}

							}
						}
					}
				}
			}
		}
	}
}

.customer-edit-info-page {
	display: flex;
	padding: 16px 16px 24px 16px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin-top: 4px;
	background: #FFF;

	.input-group-control {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 6px;
		align-self: stretch;

		label {
			color: #232F3E;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
		}

		input,
		textarea {
			display: flex;
			height: 48px;
			padding: 6px 10px;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: 5px;
			border: 1px solid #CCD4DC;
			background: #F7F8FA;
		}

	}
}

.filter_option_kit_container_bs.customer-report-filter-bs {
	.filter_option_kit_items {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;

		.option_filter_item {
			display: flex;
			height: 32px;
			padding: 0px 4px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			flex: 1 0 0;
			border-radius: 100px;
			border: 1px solid #CCD4DC;
			background: #FFF;

			span {
				color: #64748B;
				text-align: center;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 137.5%;
				/* 19.25px */
				letter-spacing: 0.28px;
			}

			&.active {
				background: #232F3E;

				span {
					color: #FFF;
				}
			}
		}

		.section-title {
			color: #232F3E;
			align-self: stretch;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 137.5%;
			letter-spacing: 0.32px;
		}

		.filter_option_kit_items_grid {
			align-self: stretch;
			display: grid;
			gap: 8px;

			&.amount-filter {
				grid-template-columns: 1fr 1fr;
			}

			&.point-amount-filter {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.filter_option_kit_item_group_range {
			margin-top: 8px;
			align-self: stretch;
			display: flex;
		}
	}
}

.slider_ui_kit {
	width: 100%;
	height: 32px;
	align-self: stretch;

	.slider {
		position: relative;
		width: 100%;
	}

	.slider__track,
	.slider__range,
	.slider__left-value,
	.slider__right-value {
		position: absolute;
	}

	.slider__track,
	.slider__range {
		border-radius: 3px;
		height: 5px;
	}

	.slider__track {
		background-color: #ced4da;
		width: 100%;
		z-index: 1;
	}

	.slider__range {
		background-color: #232F3E;
		z-index: 2;
	}

	.slider__left-value,
	.slider__right-value {
		margin-top: 10px;
		color: #64748B;
		text-align: center;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 137.5%;
		/* 19.25px */
		letter-spacing: 0.28px;
	}

	.slider__left-value {
		left: 6px;
	}

	.slider__right-value {
		right: -4px;
	}

	/* Removing the default appearance */
	.thumb,
	.thumb::-webkit-slider-thumb {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: transparent;
	}

	.thumb {
		pointer-events: none;
		position: absolute;
		height: 0;
		width: calc(100vw - 32px);
		outline: none;
	}

	.thumb--left {
		z-index: 3;
	}

	.thumb--right {
		z-index: 4;
	}

	/* For Chrome browsers */
	.thumb::-webkit-slider-thumb {
		background-color: #f1f5f7;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #232F3E;
		cursor: pointer;
		height: 18px;
		width: 18px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}

	/* For Firefox browsers */
	.thumb::-moz-range-thumb {
		background-color: #f1f5f7;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1px 1px #232F3E;
		cursor: pointer;
		height: 18px;
		width: 18px;
		margin-top: 4px;
		pointer-events: all;
		position: relative;
	}


}

.image-gallery-content-kit {
	background-color: #F6F7FC;

	.tab-container {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #CCD4DC;

		button.tab-item {
			border: none;
			background-color: transparent;
			display: flex;
			height: 54px;
			padding: 0px;
			justify-content: center;
			align-items: center;
			flex: 1 0 0;
			border-bottom: 2px solid transparent;

			&:active {
				background-color: #eee;
			}

			span {
				color: #1D2129;
				text-align: center;
				font-family: Poppins;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			&.active {
				border-bottom: 2px solid #0C719C;

				span {
					color: #0C719C;
				}
			}
		}
	}
}

.image-gallery-content-kit .footer-actions-container {
	display: flex;
	padding: 12px 16px 16px 16px;
	gap: 16px;
	background: #FFF;
	box-shadow: 0px -4px 8px -4px rgba(13, 106, 108, 0.25);

	button.btn-action {
		border: none;
		display: flex;
		height: 56px;
		padding: 16px 0px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex: 1 0 0;
		border-radius: 4px;
		background: #E5E6EB;

		span {
			color: #64748B;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}

		&.btn-upload {
			background: #0085E7;

			span {
				color: #fff;
			}
		}

		&.btn-delete {
			background: #F05326;

			span {
				color: #fff;
			}
		}
	}
}

.image-gallery-content-kit .input-search-container {
	display: flex;
	align-items: center;
	gap: 8px;
	align-self: stretch;

	.input-search-selector {
		display: flex;
		height: 48px;
		padding: 0px 16px;
		align-items: center;
		gap: 6px;
		flex: 1 0 0;
		border-radius: 5px;
		border: 1px solid #E5E6EB;
		background: #FFF;

		img.search-icon {
			width: 24px;
			height: 24px;
		}

		.input-search {
			flex: 1;
			background: transparent;
			border: none;
			outline: none;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;

			&::placeholder {
				color: #64748B;
				opacity: 1;
			}

			&::-ms-input-placeholder {
				color: #64748B;
			}

		}

	}

	&.focus .input-search-selector {
		border-color: #3A317D;
	}
}

.image-gallery-content-kit .scroll-container {
	background-color: #F6F7FC;
}

.image-gallery-content-kit .filter-container {
	padding: 16px;
	padding-bottom: 8px;
}

.image-gallery-content-kit .staff-list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	padding-top: 8px;
}

.image-gallery-content-kit .staff-media-item-container {
	display: flex;
	padding: 0px 8px 8px 8px;
	padding-bottom: 0;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	background: #FFF;
	position: relative;
	align-self: stretch;
	margin-top: 24px;

	.staff-media-item-header {
		display: flex;
		justify-content: space-between;
		align-self: stretch;
		transform: translateY(-24px);

		.staff-media-item-actions {
			display: flex;
			align-items: center;
			gap: 8px;

			button.btn {
				display: flex;
				width: 40px;
				height: 40px;
				padding: 16px 24px;
				justify-content: center;
				align-items: center;
				gap: 8px;
				border-radius: 4px;
				background: #eee;
				margin: 0;

				.btn-icon {
					width: 24px;
					height: 24px;
				}

				&.btn-cancel {
					background: #eee;
				}

				&.btn-delete {
					background: #F00;
				}

				&.btn-publish {
					background: #0085E7;
				}

				&.btn-upload {
					background: #00B52B;
				}
			}
		}
	}

	.staff-avatar-container {
		display: flex;
		padding: 4px 8px;
		align-items: center;
		gap: 8px;
		// position: absolute;
		// top: -24px;
		// left: 0px;
		border-radius: 100px;
		background: #FFF;

		.image-avatar {
			width: 48px;
			height: 48px;
			border-radius: 48px;
			object-fit: cover;
		}

		.staff-name {
			color: #232F3E;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 137.5%;
		}
	}

	.staff-images-container {
		display: grid;
		transform: translateY(-24px);
		grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
		align-self: stretch;
		gap: 4px;

		.media-item {
			height: 70px;
			width: 100%;
			position: relative;
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding: 0;

			img.media-image,
			video.media-video {
				object-fit: cover;
				width: 100%;
				height: 70px;
				border-radius: 8px;
			}

			.video-overlay {
				border-radius: 8px;
				position: absolute;
				z-index: 2;
				inset: 0;
				right: 0px;
				background: rgba(0, 0, 0, 0.4);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 30px;
				color: #fff;

				img.play-icon {
					height: 16px;
					width: 16px;
				}
			}

			.select_box {
				z-index: 3;
				position: absolute;
				right: 2.5px;
				bottom: 3px;
				width: 24px;
				height: 24px;
			}


			&:last-child {

				img.media-image,
				video.media-video {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.modal-backdrop.show.gallery-modal-backdrop {
	opacity: 0.5;
}

.modal.gallery-modal .modal-content {
	background: transparent;

	.modal-body {
		padding: 0;
	}

	.main-media {
		align-self: stretch;
		display: flex;
		align-items: center;
		margin-bottom: 8px;

		img,
		video {
			object-fit: cover;
			width: 100%;
			height: auto;
		}
	}

	.btn-close {
		display: flex;
		padding: 4px 24px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex: 1 0 0;
		border-radius: 4px;
		background: #F3F5F7;
		border: none;

		span {
			color: #64748B;
			text-align: center;
			font-family: Poppins;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.filter_option_kit_container_bs.bs-upload {
	#picture__input {
		display: none;
	}

	.picture {
		aspect-ratio: 16/9;
		background: #ddd;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #aaa;
		border: 2px dashed currentcolor;
		cursor: pointer;
		font-family: sans-serif;
		transition: color 300ms ease-in-out, background 300ms ease-in-out;
		outline: none;
		overflow: hidden;
	}

	.picture:hover {
		color: #777;
		background: #ccc;
	}

	.picture:active {
		border-color: turquoise;
		color: turquoise;
		background: #eee;
	}

	.picture:focus {
		color: #777;
		background: #ccc;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	}

	.picture__img {
		max-width: 100%;
	}

}

.staff-information-content-kit .scroll-div {
	background-color: #F6F7FC;
	padding-top: 8px;
}

.staff-information-content-kit .staff-images-container {
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	border-radius: 5px;
	background: #FFF;
}

.staff-information-content-kit .staff-images-container .top-content-container {
	display: flex;
	padding: 0px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;

	.title-section {
		color: #1D2129;
		font-family: Poppins;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	button.btn-upload {
		display: flex;
		height: 40px;
		padding: 16px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		border: none;
		box-shadow: none;
		outline: none;
		background: #0085E7;

		.upload_icon {
			width: 24px;
			height: 24px;
		}

		span {
			color: #FFF;
			text-align: center;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}

.staff-information-content-kit .staff-images-container .image-type-groups {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
}

.staff-information-content-kit .staff-images-container .image-type-item {
	display: flex;
	padding: 0px 16px 16px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
	border-bottom: 1px solid #CCD4DC;

	&:last-child {
		border-bottom: 1px solid transparent;
	}

	.label-image-group-container {
		display: flex;
		align-items: center;
		gap: 4px;

		.label-icon {
			width: 24px;
			height: 24px;
		}

		span {
			text-align: right;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}
	}

	.group-image-container {
		display: flex;
		flex-direction: column;
		align-self: stretch;

		.label-updated {
			color: #64748B;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.image-type-images-list {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
			align-self: stretch;
			gap: 4px;

			.media-item {
				height: 70px;
				width: 100%;
				position: relative;
				background-color: transparent;
				border: none;
				box-shadow: none;
				padding: 0;

				img.media-image,
				video.media-video {
					object-fit: cover;
					width: 100%;
					height: 70px;
					border-radius: 8px;
				}

				.video-overlay {
					border-radius: 8px;
					position: absolute;
					z-index: 2;
					inset: 0;
					right: 0px;
					background: rgba(0, 0, 0, 0.4);
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 30px;
					color: #fff;

					img.play-icon {
						height: 16px;
						width: 16px;
					}
				}

				.select_box {
					z-index: 3;
					position: absolute;
					right: 2.5px;
					bottom: 3px;
					width: 24px;
					height: 24px;
				}


				&:last-child {

					img.media-image,
					video.media-video {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	&.pending-tab {
		.label-image-group-container span {
			color: #F05326;
		}
	}

	&.publish-tab {
		.label-image-group-container span {
			color: #0085E7;
		}
	}
}

.salon-information-content-kit .scroll-div {
	background: #fff;

	.group-section-container {
		display: flex;
		flex-direction: column;

		.group-section-tab-container {
			display: flex;
			padding: 8px 16px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			background: #F3F5F7;

			.section-title {
				flex: 1;
				color: #1D2129;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.group-section-icon {
				width: 32px;
				height: 32px;
			}

			.btn-checkbox {
				background-color: transparent;
				box-shadow: none;
				outline: none;
				border: none;
			}

			.check-box-icon {
				width: 32px;
				height: 32px;
			}
		}

		.group-section-content-container {
			&.visible {
				display: flex;
				flex-direction: column;
			}

			&.hide {
				display: none;
			}
		}
	}
}

.salon-information-content-kit .salon-information-content-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.salon-information-content-kit .upload-banner-container {
	position: relative;

	#banner__picture__input,
	.input-handler {
		display: none;
	}

	.picture-container {
		aspect-ratio: 16/8;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #aaa;
		cursor: pointer;
		font-family: sans-serif;
		transition: color 300ms ease-in-out, background 300ms ease-in-out;
		outline: none;
		overflow: hidden;
		border-radius: 8px;
		border: 2px dashed #0C719C;
		background: #ECF4FE;

		.upload-label {
			color: #0C719C;
			text-align: center;
			font-family: Poppins;
			font-size: 12.805px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		&:hover {
			color: #777;
			background: #ccc;
		}

		&:active {
			border-color: turquoise;
			color: turquoise;
			background: #eee;
		}

		&:focus {
			color: #777;
			background: #ccc;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
		}

		.btn-edit {
			position: absolute;
			padding: 8px;
			background-color: #fff;
			border-radius: 4px;

			img.edit_icon {
				width: 24px;
				height: 24px;
			}
		}

		.btn-delete {
			position: absolute;
			top: 8px;
			right: 8px;
			padding: 8px;
			border-radius: 4px;
			border: none;
			background: #F05326;

			img.delete_icon {
				width: 24px;
				height: 24px;
			}
		}
	}

	.picture__img {
		max-width: 100%;
	}


	&.isExist {
		.picture-container {
			border: 2px dashed transparent;
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
			border-radius: 8px;
		}
	}

}

.salon-information-content-kit .salon-information-input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;

	span.label-input {
		color: #1D2129;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	input {
		display: flex;
		height: 48px;
		padding: 6px 10px;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		border-radius: 5px;
		border: 1px solid #CCD4DC;
		background: #FFF;
	}
}

.salon-information-content-kit .salon-information-section {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
}

.salon-information-content-kit .promotion-banner-section {
	padding: 16px 0;
	margin-left: -16px;
	overflow: hidden;

	.slick-list {
		padding: 0 !important;
		padding-left: 16px !important;

		.slick-slide {
			padding-left: 16px;
		}
	}
}

.salon-information-content-kit .salon-contact-section {
	display: flex;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	.salon-information-input-container {
		align-self: stretch;
	}
}

.salon-information-content-kit .salon-switch-container,
.staff-information-content-kit .salon-switch-container {
	background-color: #fff;
	padding: 16px;
	margin-bottom: 8px;
	.label-updated {
		color: #64748B;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}